import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataTablesModule} from "angular-datatables";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MaxPipe, MinPipe} from "./pipes/min.pipe";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EnvironmentPipe} from "./pipes/environment.pipe";
import {TooltipDirective} from "./directives/tooltip/tooltip.directive";
import {SortTableDirective} from "./directives/sortTable/sort-table.directive";
import {FilterPipe} from "./pipes/filter.pipe";
import {GroupPipe} from "./pipes/group.pipe";
import {BgColorDirective} from "./directives/bgColor/bgColor.directive";

@NgModule({
  declarations: SharedModule.COMPONENT_LIST,
  imports: [
    SharedModule.MODULE_LIST,
  ],
  exports: [SharedModule.MODULE_LIST, SharedModule.COMPONENT_LIST]
})
export class SharedModule {
  static readonly MODULE_LIST = [
    DataTablesModule,
    FontAwesomeModule,
    FormsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule
  ];
  static readonly COMPONENT_LIST = [
    MinPipe,
    MaxPipe,
    EnvironmentPipe,
    TooltipDirective,
    SortTableDirective,
    FilterPipe,
    GroupPipe,
    BgColorDirective
  ];
}
