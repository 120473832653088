import {createAction, props} from '@ngrx/store';
import {SubscriberModel} from "../models/subscriber.model";

export const loadSubscriber = createAction(
  '[Subscriber] Load Subscriber',
  props<{ id: string }>()
);

export const loadSubscriberSuccess = createAction(
  '[Subscriber] Load Subscriber Success',
  props<{ data: SubscriberModel }>()
);

export const loadSubscriberFailure = createAction(
  '[Subscriber] Load Subscriber Failure',
  props<{ error: any }>()
);

export const standby = createAction(
  '[Subscriber] Standby',
  props<{ id: string }>()
);

export const standbySuccess = createAction(
  '[Subscriber] Standby Success',
  props<{ data: SubscriberModel }>()
);

export const standbyFailure = createAction(
  '[Subscriber] Standby Failure',
  props<{ error: any }>()
);

export const reconnect = createAction(
  '[Subscriber] Reconnect',
  props<{ id: string }>()
);

export const reconnectSuccess = createAction(
  '[Subscriber] Reconnect Success',
  props<{ data: SubscriberModel }>()
);

export const reconnectFailure = createAction(
  '[Subscriber] Reconnect Failure',
  props<{ error: any }>()
);
