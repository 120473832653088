import {createReducer, on} from '@ngrx/store';
import * as SearchActions from '../actions/search.actions'
import {SubscriberModel} from "../models/subscriber.model";

export const SearchFeatureKey = 'search';

export interface State {
  searches: SubscriberModel[],
  loading: boolean,
  error: any

}

export const initialState: State = {
  searches: [],
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(SearchActions.loadSearch, (state) => ({...state, loading: true, error: null})),
  on(SearchActions.loadSearchSuccess, (state, {data}) => ({
    ...state,
    searches: data,
    loading: false,
    error: null
  })),
  on(SearchActions.loadSearchFailure, (state, {error}) => ({...state, loading: false, error})),
);


