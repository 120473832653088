import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  faBuilding,
  faChartLine,
  faChartSimple,
  faGaugeHigh,
  faList,
  faShop,
  faUsers,
  faHandshakeSimple
} from '@fortawesome/free-solid-svg-icons';
import {Router} from "@angular/router";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit, AfterViewInit {

  fa = {faList, faGaugeHigh, faShop, faUsers, faChartLine, faBuilding, faChartSimple, faHandshakeSimple}

  @Input() toggleMenu: any

  constructor() {
  }

  ngOnInit(): void {
  }

  @ViewChild('sideMenu') sideMenu: any;
  @ViewChild('verticalMenu') verticalMenu: any;

  ngAfterViewInit(): void {
  }

}
