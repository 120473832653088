import {createAction, props} from '@ngrx/store';
import {SourcingModel} from "../models/sourcing.model";

export const loadUser = createAction(
  '[User] Load User'
);

export const loadUserSuccess = createAction(
  '[User] Load User Success',
  props<{ data: any }>()
);

export const loadUserFailure = createAction(
  '[User] Load User Failure',
  props<{ error: any }>()
);

export const storeUser = createAction(
  '[User] Store',
  props<{ firstName: string, lastName: string, email: string, userName: string, password: string }>()
);

export const storeUserSuccess = createAction(
  '[User] Store User Success',
  props<{ data: any }>()
);

export const storeUserFailure = createAction(
  '[User] Store User Failure',
  props<{ error: any }>()
);

export const updateUser = createAction(
  '[User] Update',
  props<{ firstName: string, lastName: string, email: string, userName: string }>()
);

export const updateUserSuccess = createAction(
  '[User] Update User Success',
  props<{ data: any }>()
);

export const updateUserFailure = createAction(
  '[User] Update User Failure',
  props<{ error: any }>()
);

export const updatePassword = createAction(
  '[User] Update Password',
  props<{ password: string, confirmPassword: string }>()
);

export const updatePasswordSuccess = createAction(
  '[User] Update Password Success',
  props<{ data: any }>()
);

export const updatePasswordFailure = createAction(
  '[User] Update Password Failure',
  props<{ error: any }>()
);

export const updateGroup = createAction(
  '[User] Update Group',
  props<{ userId: string, groupId: string }>()
);

export const updateGroupSuccess = createAction(
  '[User] Update Group Success',
  props<{ data: any }>()
);

export const updateGroupFailure = createAction(
  '[User] Update Group Failure',
  props<{ error: any }>()
);

export const loadUserLastConnections = createAction(
  '[User] Load User last connection',
  props<{ id: string }>()
);

export const loadUserLastConnectionsSuccess = createAction(
  '[User] Load User last connection Success',
  props<{ data: any }>()
);

export const loadUserLastConnectionsFailure = createAction(
  '[User] Load User last connection Failure',
  props<{ error: any }>()
);

export const loadUserLastJobOffers = createAction(
  '[User] Load User last job offers',
  props<{ id: string }>()
);

export const loadUserLastJobOffersSuccess = createAction(
  '[User] Load User last job offers Success',
  props<{ data: any }>()
);

export const loadUserLastJobOffersFailure = createAction(
  '[User] Load User last job offers Failure',
  props<{ error: any }>()
);

export const loadUserHitsByDay = createAction(
  '[User] Load User HitsByDay',
  props<{ id: string }>()
);

export const loadUserHitsByDaySuccess = createAction(
  '[User] Load User HitsByDay Success',
  props<{ data: any }>()
);

export const loadUserHitsByDayFailure = createAction(
  '[User] Load User HitsByDay Failure',
  props<{ error: any }>()
);

export const loadUserGuests = createAction(
  '[User] Load User Guests',
  props<{ id: string }>()
);

export const loadUserGuestsSuccess = createAction(
  '[User] Load User Guests Success',
  props<{ data: any }>()
);

export const loadUserGuestsFailure = createAction(
  '[User] Load User Guests Failure',
  props<{ error: any }>()
);

export const loadUserCalendly = createAction(
  '[User] Load User Calendly',
  props<{ id: string }>()
);

export const loadUserCalendlySuccess = createAction(
  '[User] Load User Calendly Success',
  props<{ data: any }>()
);

export const loadUserCalendlyFailure = createAction(
  '[User] Load User Calendly Failure',
  props<{ error: any }>()
);

export const loadUserStats = createAction(
  '[User] Load User Stats',
  props<{ id: string }>()
);

export const loadUserStatsSuccess = createAction(
  '[User] Load User Stats Success',
  props<{ data: {global: [], propositions_pending: [], enterprises_visible: 0, enterprises_created: 0, propositions: [], nb_enterprises_with_propositions: [], nb_proposition_created: [], candidates_created: 0, candidates_reactivated: 0, nb_candidate_with_propositions: []} }>()
);

export const loadUserStatsFailure = createAction(
  '[User] Load User Stats Failure',
  props<{ error: any }>()
);

export const loadInactivesCandidats = createAction(
  '[User] Load Inactives Candidats',
  props<{ id: string }>()
);

export const loadInactivesCandidatsSuccess = createAction(
  '[User] Load Inactives Candidats Success',
  props<{ data: any }>()
);

export const loadInactivesCandidatsFailure = createAction(
  '[User] Load Inactives Candidats Failure',
  props<{ error: any }>()
);

export const loadAllUsers = createAction(
  '[User] Load All Users'
);

export const loadAllUsersSuccess = createAction(
  '[User] Load All Users Success',
  props<{ data: any }>()
);

export const loadAllUsersFailure = createAction(
  '[User] Load All Users Failure',
  props<{ error: any }>()
);

export const loadUserSourcing = createAction(
  '[User] Load User Sourcing'
);

export const loadUserSourcingSuccess = createAction(
  '[User] Load User Sourcing Success',
  props<{ data: SourcingModel}>()
);

export const loadUserSourcingFailure = createAction(
  '[User] Load User Sourcing Failure',
  props<{ error: any }>()
);

export const updateUserSourcing = createAction(
  '[User] Update User Sourcing',
  props<{cProfileId: string, jobOfferId: string}>()
);

export const updateUserSourcingSuccess = createAction(
  '[User] Update User Sourcing Success',
  props<{ data: SourcingModel}>()
);

export const updateUserSourcingFailure = createAction(
  '[User] Update User Sourcing Failure',
  props<{ error: any }>()
);
