<header id="page-topbar" class="isvertical-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="../../../../index.html" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/images/logo.png" alt="" height="22">
                    </span>
          <span class="logo-lg">
                        <img src="assets/images/logo.png" alt="" height="22">
                    </span>
        </a>

        <a href="index.html" class="logo logo-light">
                    <span class="logo-lg">
                        <img src="assets/images/logo.png" alt="" height="22">
                    </span>
          <span class="logo-sm"><img src="assets/images/logo.png" alt="" height="22"></span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn topnav-hamburger" (click)="toggleMenu()">
        <span class="hamburger-icon open">
            <span></span>
            <span></span>
            <span></span>
        </span>
      </button>

      <div class="d-none d-sm-block ms-3 align-self-center">
        <h4 class="page-title">{{title}}</h4>
      </div>

    </div>

    <div class="d-flex">
      <form [formGroup]="form">
        <div class="dropdown" #dropdown>
          <input type="search" class="form-control" formControlName="search" placeholder="Rechercher..."  data-bs-toggle="dropdown">
          <ul class="dropdown-menu">
            <ng-container *ngIf="loading$ | async as loading; else notLoading">
              <li *ngIf="loading" class="text-muted text-center">
                loading
              </li>
            </ng-container>
            <ng-template #notLoading>
              <ng-container *ngIf="searches$ | async as searches">
                <li *ngFor="let search of searches" [bgColor]="search">
                  <a [routerLink]="[search.profile_type === 'E' ? '/profile/enterprise' : '/profile/candidate', search.profile_type === 'E' ? search?.e_profile?.id: search?.c_profile?.id]" class="dropdown-item">
                    <div class="d-flex gap-3 w-100 align-items-center">
                      <div>
                        <div class="avatar">
                          <div class="avatar-title rounded bg-success bg-gradient" *ngIf="search.profile_type === 'E'">
                            <fa-icon [icon]="fa.faBuilding"></fa-icon>
                          </div>
                          <div class="avatar-title rounded bg-primary bg-gradient" *ngIf="search.profile_type === 'C'">
                            <fa-icon [icon]="fa.faUser"></fa-icon>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex flex-column gap-1" *ngIf="search.profile_type === 'E'">
                        <div class="fw-bold font-size-16">{{search?.e_profile?.enterprise}} / {{search.fullname}}</div>
                        <div>{{search.email}} / {{search.phone}}</div>
                      </div>
                      <div class="d-flex flex-column gap-1" *ngIf="search.profile_type === 'C'">
                        <div class="fw-bold font-size-16">{{search.fullname}}</div>
                        <div>{{search.email}} / {{search.phone}}</div>
                      </div>
                      <div class="flex-fill d-flex justify-content-end">
                        <app-avatar [eProfile]="search.e_profile" *ngIf="search.profile_type === 'E'"></app-avatar>
                        <app-avatar [subscriber]="search" *ngIf="search.profile_type === 'C'"></app-avatar>
                      </div>
                    </div>
                  </a>
                </li>
                <li *ngIf="searches.length == 0" class="text-muted text-center">
                  Aucun résults
                </li>
              </ng-container>
            </ng-template>
          </ul>
        </div>
      </form>
    </div>

    <div class="d-flex" *ngIf="user$ | async as user">

      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item user text-start d-flex align-items-center" id="page-header-user-dropdown-v"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div style="display: flex;  align-items: center;justify-content: center;">
            <div class="avatar-sm" *ngIf="user.last_name && user.first_name">
              <span class="img-thumbnail rounded-circle avatar-title bg-warning text-white" >{{user.last_name[0]}}{{user.first_name[0]}}</span>
            </div>
          </div>
        </button>
        <div class="dropdown-menu dropdown-menu-end pt-0">
          <div class="p-3 border-bottom">
            <h6 class="mb-0">{{user.last_name }} {{user.first_name}}</h6>
            <p class="mb-0 font-size-11 text-muted">{{user.email}}</p>
          </div>
          <div class="border-bottom">
            <a class="dropdown-item" [routerLink]="['user']"><i class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span class="align-middle">Profil</span></a>
            <a class="dropdown-item" [routerLink]="['user/team']"><i class="mdi mdi-account-group text-muted font-size-16 align-middle me-1"></i> <span class="align-middle">Les collègues</span></a>
          </div>
          <a class="dropdown-item" (click)="logout()" ><i class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i> <span class="align-middle">Logout</span></a>
        </div>
      </div>
    </div>
  </div>
</header>
