import {createAction, props} from '@ngrx/store';
import {SubscriberModel} from "../models/subscriber.model";
import {JobOfferModel} from "../models/jobOffer.model";
import {CProfileModel} from "../models/cProfile.model";

export const loadCProfile = createAction(
  '[CProfile] Load CProfile',
  props<{ id: string }>()
);

export const loadCProfileSuccess = createAction(
  '[CProfile] Load CProfile Success',
  props<{ data: any }>()
);

export const loadCProfileFailure = createAction(
  '[CProfile] Load CProfile Failure',
  props<{ error: any }>()
);

export const getHitStats = createAction(
  '[CProfile] Get Hit Stats',
  props<{ id: string }>()
);

export const getHitStatsSuccess = createAction(
  '[CProfile] Get Hit Stats Success',
  props<{ data: any }>()
);

export const getHitStatsFailure = createAction(
  '[CProfile] Get Hit Stats Failure',
  props<{ error: any }>()
);

export const getMatchMaking = createAction(
  '[CProfile] Get MatchMaking',
  props<{ id: string }>()
);

export const getMatchMakingSuccess = createAction(
  '[CProfile] Get MatchMaking Success',
  props<{ active: JobOfferModel[], stand_by: JobOfferModel[] }>()
);

export const getMatchMakingFailure = createAction(
  '[CProfile] Get MatchMaking Failure',
  props<{ error: any }>()
);

export const updateAvailability = createAction(
  '[CProfile] Update Availability',
  props<{ id: string, availability_status: any, availability_date: any }>()
);

export const updateAvailabilitySuccess = createAction(
  '[CProfile] Update Availability Success',
  props<{ data: CProfileModel }>()
);

export const updateAvailabilityFailure = createAction(
  '[CProfile] Update Availability Failure',
  props<{ error: any }>()
);

export const updateExperienceLevel = createAction(
  '[CProfile] Update Experience Level',
  props<{ id: string }>()
);

export const updateExperienceLevelSuccess = createAction(
  '[CProfile] Update Experience Level Success',
  props<{ data: CProfileModel }>()
);

export const updateExperienceLevelFailure = createAction(
  '[CProfile] Update Experience Level Failure',
  props<{ error: any }>()
);

export const updateRecommendationVisibility = createAction(
  '[CProfile] Update Recommendation Visibility',
  props<{ id: string, recommendation_id: string, visibility: any }>()
);

export const updateRecommendationVisibilitySuccess = createAction(
  '[CProfile] Update Recommendation Visibility Success',
  props<{ data: CProfileModel }>()
);

export const updateRecommendationVisibilityFailure = createAction(
  '[CProfile] Update Recommendation Visibility Failure',
  props<{ error: any }>()
);

export const getEmails = createAction(
  '[CProfile] Get Emails',
  props<{ id: string }>()
);

export const getEmailsSuccess = createAction(
  '[CProfile] Get Emails Success',
  props<{ data: any }>()
);

export const getEmailsFailure = createAction(
  '[CProfile] Get Emails Failure',
  props<{ error: any }>()
);

export const sendEmail = createAction(
  '[CProfile] Send Email',
  props<{ id: string, emailType: string }>()
);

export const sendEmailSuccess = createAction(
  '[CProfile] Send Email Success',
  props<{ data: any }>()
);

export const sendEmailFailure = createAction(
  '[CProfile] Send Email Failure',
  props<{ error: any }>()
);

export const importLinkedin = createAction(
  '[CProfile] Import Linkedin',
  props<{
    id: string, url: string, picture: boolean, experiences: boolean, educations: boolean,
    certifications: boolean, localisation: boolean, recommendations: boolean, skills: boolean, english: boolean,
    languages: boolean
  }>()
);

export const importLinkedinSuccess = createAction(
  '[CProfile] Import Linkedin Success',
  props<{ data: SubscriberModel }>()
);

export const importLinkedinFailure = createAction(
  '[CProfile] Import Linkedin Failure',
  props<{ error: any }>()
);
