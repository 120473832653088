import {createAction, props} from '@ngrx/store';

export const loadSchools = createAction(
  '[Education] Load Schools'
);

export const loadSchoolsSuccess = createAction(
  '[Education] Load Schools Success',
  props<{ data: any }>()
);

export const loadSchoolsFailure = createAction(
  '[Education] Load Schools Failure',
  props<{ error: any }>()
);
