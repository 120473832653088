import {createAction, props} from '@ngrx/store';
import {PushModel} from "../models/push.model";

export const loadPush = createAction(
  '[Push] Load Push'
);

export const loadPushSuccess = createAction(
  '[Push] Load Push Success',
  props<{ data: PushModel }>()
);

export const loadPushFailure = createAction(
  '[Push] Load Push Failure',
  props<{ error: any }>()
);

export const storePush = createAction(
  '[Push] Store Push',
  props<{ e_profile_id: number | string }>()
);

export const storePushSuccess = createAction(
  '[Push] Store Push Success',
  props<{ data: PushModel }>()
);

export const storePushFailure = createAction(
  '[Push] Store Push Failure',
  props<{ error: any }>()
);

export const updatePush = createAction(
  '[Push] update Push',
  props<{id: string, status: string, title: string | null}>()
);

export const updatePushSuccess = createAction(
  '[Push] Update Push Success',
  props<{ data: PushModel }>()
);

export const updatePushFailure = createAction(
  '[Push] Update Push Failure',
  props<{ error: any }>()
);

export const deletePush = createAction(
  '[Push] Delete Push',
  props<{ id: string }>()
);

export const deletePushSuccess = createAction(
  '[Push] Delete Push Success',
  props<{ data: PushModel }>()
);

export const deletePushFailure = createAction(
  '[Push] Delete Push Failure',
  props<{ error: any }>()
);

export const storePushCProfile = createAction(
  '[Push] Store PushCProfile',
  props<{ c_profile_id: string }>()
);

export const storePushCProfileSuccess = createAction(
  '[Push] Store PushCProfile Success',
  props<{ data: PushModel }>()
);

export const storePushCProfileFailure = createAction(
  '[Push] Store PushCProfile Failure',
  props<{ error: any }>()
);

export const deletePushCProfile = createAction(
  '[Push] Delete PushCProfile',
  props<{ id: string, c_profile_id: string }>()
);

export const deletePushCProfileSuccess = createAction(
  '[Push] Delete PushCProfile Success',
  props<{ data: PushModel }>()
);

export const deletePushCProfileFailure = createAction(
  '[Push] Delete PushCProfile Failure',
  props<{ error: any }>()
);

