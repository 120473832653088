import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './shared/ui/footer/footer.component';
import {SidebarComponent} from './shared/ui/sidebar/sidebar.component';
import {TopBarComponent} from './shared/ui/top-bar/top-bar.component';
import {SimplebarAngularModule} from "./shared/ui/SimplebarAngular/Simplebar-angular.module";
import {MetismenuModule} from "./shared/ui/metismenu/metismenu.module";
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AuthInterceptor} from "./services/auth.interceptor";
import {environment} from "../environments/environment";
import {NgxsModule} from "@ngxs/store";
import {AuthState} from "./state/auth.state";
import {NgxsStoragePluginModule} from "@ngxs/storage-plugin";
import {UserState} from "./state/user.state";
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {SharedModule} from "./shared/shared.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import { ServiceWorkerModule } from '@angular/service-worker';
import {CheckForUpdateService} from "./check-for-update.service";
import {PromptUpdateService} from "./prompt-update.service";
import {QuillConfigModule, QuillModule} from "ngx-quill";
import {PropositionEffects} from "./core/effects/proposition.effects";
import {EffectsModule} from "@ngrx/effects";
import {metaReducers, reducers} from "./reducers";
import {StoreModule} from "@ngrx/store";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {CProfileEffects} from "./core/effects/cProfile.effects";
import {UserEffects} from "./core/effects/user.effects";
import {SourcingEffects} from "./core/effects/sourcing.effects";
import {FireworkModule} from "./shared/ui/firework/firework.module";
import {EProfileEffects} from "./core/effects/eProfile.effects";
import {CommentaryEffects} from "./core/effects/commentary.effects";
import {EducationEffects} from "./core/effects/education.effects";
import {PushEffects} from "./core/effects/push.effects";
import {OffcanvasPushModule} from "./shared/ui/offcanvas-push/offcanvas-push.module";
import {JobOfferEffects} from "./core/effects/jobOffer.effects";
import {SearchEffects} from "./core/effects/search.effects";
import {AvatarModule} from "./shared/ui/avatar/avatar.module";
import {SubscriberEffects} from "./core/effects/subscriber.effects";
import {Router} from "@angular/router";
import * as Sentry from "@sentry/angular";

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    SidebarComponent,
    TopBarComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SimplebarAngularModule,
    MetismenuModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgxsModule.forRoot([AuthState, UserState], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      key: [AuthState, UserState]
    }),
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      progressBar: true,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:3000'
    }),
    QuillModule.forRoot(),
    QuillConfigModule.forRoot({
      format: 'html',
      sanitize: true,
      modules: {
        syntax: false,
        toolbar: [['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          // ['blockquote', 'code-block'],

          [{'header': 1}, {'header': 2}],               // custom button values
          [{'list': 'ordered'}, {'list': 'bullet'}],
          // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
          // [{ 'direction': 'rtl' }],                         // text direction

          [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
          [{'header': [1, 2, 3, 4, 5, 6, false]}],

          [{'color': []}, {'background': []}],          // dropdown with defaults from theme
          // [{ 'font': [] }],
          [{'align': []}],

          ['clean'],                                         // remove formatting button

          // ['link', 'image', 'video']
        ]
      }
    }),
    StoreModule.forRoot(reducers, {metaReducers}),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([PropositionEffects, CProfileEffects, EProfileEffects, CommentaryEffects,
      UserEffects, SourcingEffects, EducationEffects, PushEffects, JobOfferEffects, SearchEffects, SubscriberEffects]),
    FireworkModule,
    OffcanvasPushModule,
    AvatarModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    CheckForUpdateService,
    PromptUpdateService
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
