import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as PropositionActions from '../actions/proposotion.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of, tap} from 'rxjs';
import {PropositionService} from "../apis/proposition.service";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class PropositionEffects {
  constructor(private actions$: Actions, private propositionService: PropositionService, private store: Store,
              private toast: ToastrService) {
  }

  loadProposition$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PropositionActions.loadProposition),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.propositionService.loadProposition(id).pipe(
            map(data => PropositionActions.loadPropositionSuccess({data})),
            catchError(error => of(PropositionActions.loadPropositionFailure({error})))
          );
        })
      )
    }
  );

  updatePropositionTag$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PropositionActions.updatePropositionTag),
        map((action: any) => action),
        mergeMap(({id, tagId, comment}) => {
          return this.propositionService.updateTag(id, tagId, comment).pipe(
            map(data => PropositionActions.updatePropositionTagSuccess({data})),
            catchError(error => of(PropositionActions.updatePropositionTagFailure({error})))
          );
        })
      )
    }
  );

  updatePropositionTagSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PropositionActions.updatePropositionTagSuccess),
        tap(() => {
          this.toast.success('tag ajouté')
        }),
      );
    },
    { dispatch: false }
  );

  updatePropositionTagCommentDate$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PropositionActions.updatePropositionTagCommentDate),
        map((action: any) => action),
        mergeMap(({id, propositionTagId, comment, date}) => {
          return this.propositionService.updateCommentDateTag(id, propositionTagId, comment, date).pipe(
            map(data => PropositionActions.updatePropositionTagCommentDateSuccess({data})),
            catchError(error => of(PropositionActions.updatePropositionTagCommentDateFailure({error})))
          );
        })
      )
    }
  );

  updatePropositionTagCommentDateSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PropositionActions.updatePropositionTagCommentDateSuccess),
        tap(() => {
          this.toast.success('Commentaire et date sauvegardé')
        }),
      );
    },
    { dispatch: false }
  );

  deletePropositionTag$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PropositionActions.deletePropositionTag),
        map((action: any) => action),
        mergeMap(({id, propositionTagId}) => {
          return this.propositionService.deleteTag(id, propositionTagId).pipe(
            map(data => PropositionActions.deletePropositionTagSuccess({data})),
            catchError(error => of(PropositionActions.deletePropositionTagFailure({error})))
          );
        })
      )
    }
  );

  deletePropositionTagSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PropositionActions.deletePropositionTagSuccess),
        tap(() => {
          this.toast.success('tag supprimé')
        }),
      );
    },
    { dispatch: false }
  );

  loadInProgress$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PropositionActions.loadInProgress),
        map((action: any) => action),
        mergeMap(({userId, search}) => {
          return this.propositionService.getInProgress(userId, search).pipe(
            map(data => PropositionActions.loadInProgressSuccess({data})),
            catchError(error => of(PropositionActions.loadInProgressFailure({error})))
          );
        })
      )
    }
  );
}
