import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[bgColor]'
})
export class BgColorDirective implements OnInit {

  @Input('bgColor')
  subscriber: any;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    let base = 'bg-'
    switch (this.el.nativeElement.tagName.toLowerCase()) {
      case 'tr':
        base = 'table-'
        break
    }

    if (this.subscriber.approval == 0) {
    } else if (this.subscriber.status == 0) {
      this.el.nativeElement.classList.add(base + 'info')
    } else if (this.subscriber.status == 2) {
      this.el.nativeElement.classList.add(base + 'secondary')
    } else if (this.subscriber.status == 1) {
      switch (this.subscriber.availability) {
        case 0:
          this.el.nativeElement.classList.add(base + 'success')
          break
        case 1:
          this.el.nativeElement.classList.add(base + 'danger')
          break
        case 2:
          this.el.nativeElement.classList.add(base + 'warning')
          break
      }
    }
  }
}
