import {createAction, props} from '@ngrx/store';
import {JobOfferModel} from "../models/jobOffer.model";
import {CProfileModel} from "../models/cProfile.model";

export const loadJobOffer = createAction(
  '[Proposition] Load JobOffer',
  props<{ id: string }>()
);

export const loadJobOfferSuccess = createAction(
  '[JobOffer] Load JobOffer Success',
  props<{ data: any }>()
);

export const loadJobOfferFailure = createAction(
  '[JobOffer] Load JobOffer Failure',
  props<{ error: any }>()
);

export const updateStatus = createAction(
  '[JobOffer] Update Status',
  props<{id: string, status: string}>()
);

export const updateStatusSuccess = createAction(
  '[JobOffer] Update Status Success',
  props<{ data: JobOfferModel }>()
);

export const updateStatusFailure = createAction(
  '[JobOffer] Update Status Failure',
  props<{ error: any }>()
);

export const updateInternalStatus = createAction(
  '[JobOffer] Update Internal Status',
  props<{id: string, status: string | null}>()
);

export const updateInternalStatusSuccess = createAction(
  '[JobOffer] Update Internal Status Success',
  props<{ data: JobOfferModel }>()
);

export const updateInternalStatusFailure = createAction(
  '[JobOffer] Update Internal Status Failure',
  props<{ error: any }>()
);

export const getSourcing = createAction(
  '[JobOffer] Get Sourcing',
  props<{ id: string }>()
);

export const getSourcingSuccess = createAction(
  '[JobOffer] Get Sourcing Success',
  props<{ data: CProfileModel[] }>()
);

export const getSourcingFailure = createAction(
  '[JobOffer] Get Sourcing Failure',
  props<{ error: any }>()
);

export const getMatchMaking = createAction(
  '[JobOffer] Get MatchMaking',
  props<{ id: string }>()
);

export const getMatchMakingSuccess = createAction(
  '[JobOffer] Get MatchMaking Success',
  props<{ data: CProfileModel[] }>()
);

export const getMatchMakingFailure = createAction(
  '[JobOffer] Get MatchMaking Failure',
  props<{ error: any }>()
);
