export const environment = {
  production: true,
  NAME: 'production',

  API_URL: 'https://api.fiftytalents.com/admin',
  DATA_URL: 'https://api.fiftytalents.com/data/',
  APP_URL: 'https://app.fiftytalents.com/',
  API_OAUTH_URL: 'https://api.fiftytalents.com/oauth/token',
  OAUTH_CLIENT: '27',
  OAUTH_SECRET: 'V06TnomqYg16tYmJ4m6pAdmEFTybE4mK9ysiUeuE',
};
