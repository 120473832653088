import {isDevMode} from '@angular/core';
import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import * as fromPropositionReducer from '../core/reducers/proposition.reducer'
import * as fromCProfileReducer from '../core/reducers/cProfile.reducer'
import * as fromEProfileReducer from '../core/reducers/eProfile.reducer'
import * as fromUserReducer from '../core/reducers/user.reducer'
import * as fromSourcingReducer from '../core/reducers/sourcing.reducer'
import * as fromCommentaryReducer from '../core/reducers/commentary.reducer'
import * as fromEducationReducer from '../core/reducers/education.reducer'
import * as fromPushReducer from '../core/reducers/push.reducer'
import * as fromJobOfferReducer from '../core/reducers/jobOffer.reducer'
import * as fromSearchReducer from '../core/reducers/search.reducer'
import * as fromSubscriberReducer from '../core/reducers/subscriber.reducer'

export interface State {
  proposition: fromPropositionReducer.State
  cProfile: fromCProfileReducer.State
  eProfile: fromEProfileReducer.State
  user: fromUserReducer.State,
  sourcing: fromSourcingReducer.State
  commentary: fromCommentaryReducer.State
  education: fromEducationReducer.State
  push: fromPushReducer.State
  jobOffer: fromJobOfferReducer.State
  search: fromSearchReducer.State,
  subscriber: fromSubscriberReducer.State
}

export const reducers: ActionReducerMap<State> = {
  proposition: fromPropositionReducer.reducer,
  cProfile: fromCProfileReducer.reducer,
  eProfile: fromEProfileReducer.reducer,
  user: fromUserReducer.reducer,
  sourcing: fromSourcingReducer.reducer,
  commentary: fromCommentaryReducer.reducer,
  education: fromEducationReducer.reducer,
  push: fromPushReducer.reducer,
  jobOffer: fromJobOfferReducer.reducer,
  search: fromSearchReducer.reducer,
  subscriber: fromSubscriberReducer.reducer
};


export const metaReducers: MetaReducer<State>[] = isDevMode() ? [] : [];
