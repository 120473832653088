import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";

const API_URL = environment.API_URL;
const PATH = '/proposition/'

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class PropositionService {

  constructor(private http: HttpClient) {
  }

  loadProposition(id: string) {
    return this.http.get<any>(API_URL + PATH + id)
  }

  updateTag(id: number, tagId: number, comment?: any): Observable<any> {
    return this.http.patch<any>(API_URL + PATH + id + '/tag/' + tagId, {comment})
  }

  updateCommentDateTag(id: number, propositionTagId: number, comment: any, date: any) {
    return this.http.patch<any>(API_URL + PATH + id + '/tag/' + propositionTagId + '/comment', {comment, date})
  }

  deleteTag(id: number, propositionTagId: number) {
    return this.http.delete<any>(API_URL + PATH + id + '/tag/' + propositionTagId)
  }

  getInProgress(userId: number, search: any = ''): Observable<any> {
    return this.http.get<any>(API_URL + PATH + 'inProgress/', {params: {userId, search}})
  }

  table(body: any, userId: string): Observable<DataTablesResponse> {
    return this.http.post<any>(API_URL + PATH + 'table/', body, {params: {userId}})
  }
}
