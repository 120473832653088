import {createAction, props} from '@ngrx/store';

export const loadProposition = createAction(
  '[Proposition] Load Proposition',
  props<{ id: string }>()
);

export const loadPropositionSuccess = createAction(
  '[Proposition] Load Proposition Success',
  props<{ data: any }>()
);

export const loadPropositionFailure = createAction(
  '[Proposition] Load Proposition Failure',
  props<{ error: any }>()
);

export const updatePropositionTag = createAction(
  '[Proposition] Update Proposition Tag',
  props<{ id: string, tagId: number, comment?: any}>()
);

export const updatePropositionTagSuccess = createAction(
  '[Proposition] Update Proposition Tag Success',
  props<{ data: any }>()
);

export const updatePropositionTagFailure = createAction(
  '[Proposition] Update Proposition Tag Failure',
  props<{ error: any }>()
);

export const updatePropositionTagCommentDate = createAction(
  '[Proposition] Update Proposition Tag comment date',
  props<{ id: string, propositionTagId: string, comment: any, date: any}>()
);

export const updatePropositionTagCommentDateSuccess = createAction(
  '[Proposition] Update Proposition Tag comment date Success',
  props<{ data: any }>()
);

export const updatePropositionTagCommentDateFailure = createAction(
  '[Proposition] Update Proposition Tag comment date Failure',
  props<{ error: any }>()
);

export const deletePropositionTag = createAction(
  '[Proposition] Delete Proposition Tag',
  props<{ id: string, propositionTagId: string}>()
);

export const deletePropositionTagSuccess = createAction(
  '[Proposition] Delete Proposition Tag Success',
  props<{ data: any }>()
);

export const deletePropositionTagFailure = createAction(
  '[Proposition] Delete Proposition Tag Failure',
  props<{ error: any }>()
);

export const loadInProgress = createAction(
  '[Proposition] Load In Progress',
  props<{ userId: string, search?: any}>()
);

export const loadInProgressSuccess = createAction(
  '[Proposition] Load In Progress Success',
  props<{ data: any }>()
);

export const loadInProgressFailure = createAction(
  '[Proposition] Load In Progress Failure',
  props<{ error: any }>()
);

