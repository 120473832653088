import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromSearchReducer from '../reducers/search.reducer';

export const getSearchState = createFeatureSelector<fromSearchReducer.State>('search');

export const loading = createSelector(getSearchState, (state: fromSearchReducer.State) => state.loading);

export const selectError = createSelector(getSearchState, (state) => state.error);

export const selectSearches = createSelector(getSearchState, (state: fromSearchReducer.State) => state.searches);
