import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {Logout} from "./models/Auth";
import {Actions, ofActionDispatched, Store} from "@ngxs/store";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AuthState} from "./state/auth.state";
import {Title} from "@angular/platform-browser";
import {filter, Subject, takeUntil} from "rxjs";
import {map} from "rxjs/operators";
import {DepartmentsStore} from "./profile/data-access/departments.store";
import {JobsStore} from "./shared/data-access/jobs.store";
import {StatesStore} from "./profile/data-access/states.store";
import {CheckForUpdateService} from "./check-for-update.service";
import {PromptUpdateService} from "./prompt-update.service";
import {TagsStore} from "./shared/data-access/tag.store";
import {SkillsStore} from "./shared/data-access/skills.store";
import {SectorsStore} from "./shared/data-access/sectors.store";
import * as UserActions from "./core/actions/user.actions";
import {Store as StoreNgrx} from "@ngrx/store";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DepartmentsStore, JobsStore, StatesStore, TagsStore, SkillsStore, SectorsStore],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  isAuthenticate = false;
  isError = false;
  title = '';
  displayFirework: boolean = false

  ngUnsubscribe = new Subject<void>();

  constructor(private actions: Actions, private router: Router, private store: Store,
              private activatedRoute: ActivatedRoute, private titleService: Title, private storeNgrx: StoreNgrx,
              private checkUpdate: CheckForUpdateService, private promptUpdateService: PromptUpdateService, private ref: ChangeDetectorRef) {
    setTimeout(() => {
      this.displayFirework = false
      this.ref.markForCheck()
    }, 5000)

    this.store.select(AuthState.isAuthenticated).pipe(takeUntil(this.ngUnsubscribe)).subscribe(isAuthenticate => {
      this.isAuthenticate = isAuthenticate
      if (isAuthenticate) {
        setTimeout(() => {
          this.storeNgrx.dispatch(UserActions.loadAllUsers())
          this.storeNgrx.dispatch(UserActions.loadUser())
        }, 1)
      }
    })

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        if (this.router.url === '/404' || this.router.url === '/500') {
          this.isError = true
        }
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            return child.snapshot.data;
          } else {
            return null;
          }
        }
        return null;
      })
    ).pipe(takeUntil(this.ngUnsubscribe)).subscribe( (data: any) => {
      if (data) {
        this.title = data.title
        this.titleService.setTitle(data.title + ' - Admin FiftyTalents');
        if (data.observable$) {
          data.observable$.subscribe((data: any) => {
            // if (data) {
            //   if (data.subscriber_id) {
            //     this.title = this.title + ' - #' + data.subscriber_id
            //   } else if (data.id) {
            //     this.title = this.title + ' - #' + data.id
            //   }
            // }
          })
        }
      }
    });
  }

  ngOnInit(): void {
    this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.router.navigate(['/login']);
    })
  }

  ngAfterViewInit() {
  }

  toggleMenu(): void {
    const currentSIdebarSize = document.body.getAttribute("data-sidebar-size");
    document.body.classList.toggle("sidebar-enable");
    if (window.screen.width >= 992) {
      if (currentSIdebarSize == null) {
        document.body.getAttribute("data-sidebar-size") == null ||
        document.body.getAttribute("data-sidebar-size") == "lg"
          ? document.body.setAttribute("data-sidebar-size", "sm")
          : document.body.setAttribute("data-sidebar-size", "lg");
      } else if (currentSIdebarSize == "md") {
        document.body.getAttribute("data-sidebar-size") == "md"
          ? document.body.setAttribute("data-sidebar-size", "sm")
          : document.body.setAttribute("data-sidebar-size", "md");
      } else {
        document.body.getAttribute("data-sidebar-size") == "sm"
          ? document.body.setAttribute("data-sidebar-size", "lg")
          : document.body.setAttribute("data-sidebar-size", "sm");
      }
    } else {
      document.body.classList.remove("vertical-collpsed");
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
