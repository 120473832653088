import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import MetisMenu from 'metismenujs';
import {IMMOptions} from 'metismenujs/dist/types/interface';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {map} from "rxjs/operators";

export type toggleType = 'true' | 'false';

@Component({
  selector: 'metis-menu, [metis-menu]',
  // inputs: ['toggle', 'parentTrigger', 'subMenu', 'triggerElement'],
  templateUrl: './metismenu.component.html',
  styleUrls: ['./metismenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,

})
export class MetismenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() toggle: toggleType = 'false';
  @Input() parentTrigger = 'li';
  @Input() subMenu = 'ul';
  @Input() triggerElement = 'a';

  protected mm!: MetisMenu;
  mmOpts!: IMMOptions;
  private currentRoute: any = null;
  private prevRoute: string;

  constructor(private elem: ElementRef, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngAfterViewInit(): void {
    this.mmOpts = {
      toggle: (this.toggle.toLowerCase() === 'true'),
      parentTrigger: this.parentTrigger || 'li',
      subMenu: this.subMenu || 'ul',
      triggerElement: this.triggerElement || 'a',
    };
  }

  ngOnInit(): void {
    this.mm = new MetisMenu(this.elem.nativeElement, this.mmOpts);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((data: any) => {
        return data.url
      })
    ).subscribe( (data: any) => {
      if (data) {
        this.prevRoute = this.currentRoute
        this.currentRoute = data
        let isCurrentRoute = false
        let isPrevRoute = false

        const links = Array.from(document.getElementsByClassName("side-nav-link-ref"));
        let currentMenuItem: Element;
        let prevMenuItem: Element;

        links.forEach((link) => {
          let path = link.getAttribute('routerLink')
          if (path) {
            isCurrentRoute = this.currentRoute === path
            isPrevRoute = this.prevRoute === path

            if (isPrevRoute) {
              prevMenuItem = link;
            } else if (isCurrentRoute) {
              currentMenuItem = link;
            }
          }
        })

        let currentParent
        let currentParent2
        let currentParent3
        let prevParent
        let prevParent2
        let prevParent3

        // @ts-ignore
        if (typeof currentMenuItem !== 'undefined') {
          currentParent = currentMenuItem.parentElement;
          if (currentParent) {
            currentParent2 = currentParent.parentElement
            if (currentParent2) {
              currentParent2 = currentParent2.closest("ul");
            }
            if (currentParent2 && currentParent2.id !== "side-menu") {
              currentParent3 = currentParent2.parentElement;
            }
          }
        }
        // @ts-ignore
        if (typeof prevMenuItem !== 'undefined') {
          prevParent = prevMenuItem.parentElement;
          if (prevParent) {
            prevParent2 = prevMenuItem.parentElement
            if (prevParent2) {
              prevParent2 = prevMenuItem.closest("ul");
            }
            if (prevParent2 && prevParent2.id !== "side-menu") {
              prevParent3 = prevMenuItem.parentElement;
            }
          }
        }

        if (currentParent) {
          currentParent.classList.add("mm-active");
        }
        if (prevParent) {
          prevParent.classList.remove("mm-active");
        }

        if (currentParent2 && currentParent3) {
           if (!currentParent3.classList.contains("mm-active")) {
             // @ts-ignore
             this.mm.toggle(currentParent2);
           }
        }

        if (prevParent2 && prevParent3 && !currentParent3) {
          // @ts-ignore
          this.mm.toggle(prevParent2);
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.mm.dispose();
  }
}
