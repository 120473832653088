import {ChangeDetectorRef, Injectable} from "@angular/core";
import {Store} from "./store";
import {SectorService} from "./sector.service";

@Injectable()
export class SectorsStore extends Store<[]|null> {
  constructor (private ref: ChangeDetectorRef, private sectorService: SectorService) {
    super(null);
    this.get()
  }

  get(): void {
    this.sectorService.getAll().subscribe(data => {
      this.setState(data);
      this.ref.markForCheck()
    });
  }
}
