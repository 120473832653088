import {ChangeDetectorRef, Injectable} from "@angular/core";
import {Store} from "../../shared/data-access/store";
import {DepartmentService} from "./department.service";

@Injectable({
  providedIn: 'root',
})
export class DepartmentsStore extends Store<[]|null> {

  constructor (private departmentService: DepartmentService, private ref: ChangeDetectorRef) {
    super(null);
  }

  get(): void {
    this.departmentService.getAll().subscribe(data => {
      this.setState(data);
      this.ref.markForCheck()
    });
  }

  getWithJobs(): void {
    this.departmentService.getWithJobs().subscribe(data => {
      this.setState(data);
      this.ref.markForCheck()
    });
  }
}
