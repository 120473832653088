import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromUserReducer from '../reducers/user.reducer';

export const getUserState = createFeatureSelector<fromUserReducer.State>('user');
export const loading = createSelector(getUserState, (state: fromUserReducer.State) => state.loading);

export const selectError = createSelector(getUserState, (state) => state.error);

export const selectUser = createSelector(getUserState, (state: fromUserReducer.State) => state.user);

export const selectAllUsers = createSelector(getUserState, (state: fromUserReducer.State) => state.all);

export const selectUserLastConnections = createSelector(getUserState, (state: fromUserReducer.State) => state.last_connections);

export const selectUserLastJobOffers = createSelector(getUserState, (state: fromUserReducer.State) => state.last_job_offers);

export const selectUserHitsByDays = createSelector(getUserState, (state: fromUserReducer.State) => state.hits_by_days);

export const selectUserGuests = createSelector(getUserState, (state: fromUserReducer.State) => state.guests);

export const selectUserCalendly = createSelector(getUserState, (state: fromUserReducer.State) => state.calendly);

export const selectUserStats = createSelector(getUserState, (state: fromUserReducer.State) => state.stats);

export const selectUserSourcing = createSelector(getUserState, (state: fromUserReducer.State) => state.sourcing);

export const selectUserInactives = createSelector(getUserState, (state: fromUserReducer.State) => state.inactives);

