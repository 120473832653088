import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {SubscriberModel} from "../models/subscriber.model";


const API_URL = environment.API_URL;
const PATH = '/subscriber/'

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  constructor(private http: HttpClient) {
  }

  loadSubscriber(id: string): Observable<any> {
    return this.http.get<SubscriberModel>(API_URL + PATH + id)
  }

  standby(id: string): Observable<any> {
    return this.http.patch<SubscriberModel>(API_URL + PATH + id + '/standby', {})
  }

  reconnect(id: string): Observable<any> {
    return this.http.patch<SubscriberModel>(API_URL + PATH + id + '/reconnect', {})
  }
}
