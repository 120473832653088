import {createAction, props} from '@ngrx/store';

export const loadSearch = createAction(
  '[Search] Load Search',
  props<{ search: any }>()
);

export const loadSearchSuccess = createAction(
  '[Search] Load Search Success',
  props<{ data: any }>()
);

export const loadSearchFailure = createAction(
  '[Search] Load Search Failure',
  props<{ error: any }>()
);
