import {createAction, props} from '@ngrx/store';

export const loadCommentaries = createAction(
  '[Commentary] Load Commentaries',
  props<{ profile: string, profile_id: string }>()
);

export const loadCommentariesSuccess = createAction(
  '[Commentary] Load Commentaries Success',
  props<{ data: any }>()
);

export const loadCommentariesFailure = createAction(
  '[Commentary] Load Commentaries Failure',
  props<{ error: any }>()
);

export const createCommentary = createAction(
  '[Commentary] Create Commentary',
  props<{ profile: string, profile_id: string, comment: string, proposition_id: string | null }>()
);

export const createCommentarySuccess = createAction(
  '[Commentary] Create Commentary Success',
  props<{ data: any }>()
);

export const createCommentaryFailure = createAction(
  '[Commentary] Create Commentary Failure',
  props<{ error: any }>()
);

export const updateCommentary = createAction(
  '[Commentary] Update Commentary',
  props<{ commentary_id: string, comment: string}>()
);

export const updateCommentarySuccess = createAction(
  '[Commentary] Update Commentary Success',
  props<{ data: any }>()
);

export const updateCommentaryFailure = createAction(
  '[Commentary] Update Commentary Failure',
  props<{ error: any }>()
);

export const deleteCommentary = createAction(
  '[Commentary] Delete Commentary',
  props<{ commentary_id: string }>()
);

export const deleteCommentarySuccess = createAction(
  '[Commentary] Delete Commentary Success',
  props<{ data: any }>()
);

export const deleteCommentaryFailure = createAction(
  '[Commentary] Delete Commentary Failure',
  props<{ error: any }>()
);
