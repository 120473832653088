import {AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import {Sort} from './sort'

@Directive({
  selector: '[sortTable]'
})
export class SortTableDirective implements OnInit, AfterViewInit {

  @Input() sortTable: Array<any>;

  constructor(private renderer: Renderer2, private targetElement: ElementRef) {
  }

  ngOnInit(): void {
    const elem = this.targetElement.nativeElement;

    const node = document.createElement("span");
    node.className = 'd-flex'
    node.style.cursor = 'pointer'

    const node1 = document.createElement("span");
    node1.className = 'w-100'
    node1.textContent = elem.textContent
    elem.textContent = ''

    const node2 = document.createElement("span");
    const i = document.createElement("i");
    i.className = 'fas fa-sort'
    node2.className = 'flex-shrink-1'
    node2.append(i)

    node.append(node1)
    node.append(node2)

    elem.append(node)
  }

  ngAfterViewInit(): void {
    const elem = this.targetElement.nativeElement;
    const sort = elem.getAttribute("data-sort");

    if (sort === 'true') {
      this.sortData()
    }
  }

  @HostListener("click")
  sortData() {
    const sort = new Sort();

    const elem = this.targetElement.nativeElement;

    const order = elem.getAttribute("data-order");

    const is = elem.parentElement.getElementsByTagName('i')

    for (const i of is) {
      i.className = 'fas fa-sort'
    }

    const i = elem.getElementsByTagName('i')[0]
    if (order === 'desc') {
      i.className = 'fas fa-sort-up'
    } else {
      i.className = 'fas fa-sort-down'
    }

    const type = elem.getAttribute("data-type");
    const property = elem.getAttribute("data-name");

    if (order === "desc") {
      this.sortTable.sort(sort.startSort(property, order, type));
      elem.setAttribute("data-order", "asc");
    } else {
      this.sortTable.sort(sort.startSort(property, order, type));
      elem.setAttribute("data-order", "desc");
    }
  }
}
