import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {SourcingModel} from "../models/sourcing.model";

const API_URL = environment.API_URL;
const PATH = '/user/'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  loadUser() {
    return this.http.get<any>(API_URL + PATH)
  }

  create(firstName: string, lastName: string, email: string, userName: string, password: string): Observable<any> {
    return this.http.post<any>(API_URL + PATH, {firstName, lastName, email, userName, password})
  }

  update(firstName: string, lastName: string, email: string, userName: string): Observable<any> {
    return this.http.patch<any>(API_URL + PATH, {firstName, lastName, email, userName})
  }

  updatePassword(password: string, confirmPassword: string): Observable<any> {
    return this.http.patch<any>(API_URL + PATH + 'password/', {password, password_confirmation: confirmPassword})
  }

  updateGroup(userId: string, groupId: string): Observable<any> {
    return this.http.patch<any>(API_URL + PATH + userId + '/group/' + groupId, null)
  }

  loadAllUsers() {
    return this.http.get<any>(API_URL + PATH + 'all')
  }

  loadLastConnections(id: string) {
    return this.http.get<any>(API_URL + PATH + id + '/lastConnections')
  }

  loadLastJobOffers(id: string) {
    return this.http.get<any>(API_URL + PATH + id + '/lastJobOffers')
  }

  loadHitsByDays(id: string) {
    return this.http.get<any>(API_URL + PATH + id + '/hitsByDays')
  }

  loadGuests(id: string) {
    return this.http.get<any>(API_URL + PATH + id + '/guests')
  }

  loadCalendly(id: string) {
    return this.http.get<any>(API_URL + PATH + id + '/calendly')
  }

  loadInactivesCandidats(id: string) {
    return this.http.get<any>(API_URL + PATH + id + '/inactivesCandidats')
  }

  loadStats(id: string) {
    return this.http.get<any>(API_URL + PATH  + id + '/stats')
  }

  loadSourcing() {
    return this.http.get<any>(API_URL + PATH + 'sourcing')
  }

  updateSourcing(cProfileId: string, jobOfferId: number) {
    return this.http.post<SourcingModel>(API_URL + PATH + 'sourcing', {cProfileId, jobOfferId})
  }
}
