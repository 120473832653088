import {createReducer, on} from '@ngrx/store';
import * as PushAction from '../actions/push.actions'
import {PushModel} from "../models/push.model";

export const PushFeatureKey = 'push';

export interface State {
  current: PushModel | null,
  loading: boolean,
  error: any
}

export const initialState: State = {
  current: null,
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(PushAction.loadPush, (state) => ({...state, loading: true, error: null})),
  on(PushAction.loadPushSuccess, (state, {data}) => ({
    ...state,
    current: Object.keys(data).length === 0 ? null : data,
    loading: false,
    error: null
  })),
  on(PushAction.loadPushFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PushAction.storePush, (state) => ({...state, loading: true, error: null})),
  on(PushAction.storePushSuccess, (state, {data}) => ({
    ...state,
    current: Object.keys(data).length === 0 ? null : data,
    loading: false,
    error: null
  })),
  on(PushAction.storePushFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PushAction.updatePush, (state) => ({...state, loading: true, error: null})),
  on(PushAction.updatePushSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(PushAction.updatePushFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PushAction.deletePush, (state) => ({...state, loading: true, error: null})),
  on(PushAction.deletePushSuccess, (state, {data}) => ({
    ...state,
    current: null,
    loading: false,
    error: null
  })),
  on(PushAction.deletePushFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PushAction.storePushCProfile, (state) => ({...state, loading: true, error: null})),
  on(PushAction.storePushCProfileSuccess, (state, {data}) => ({
    ...state,
    current: Object.keys(data).length === 0 ? null : data,
    loading: false,
    error: null
  })),
  on(PushAction.storePushCProfileFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PushAction.deletePushCProfile, (state) => ({...state, loading: true, error: null})),
  on(PushAction.deletePushCProfileSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(PushAction.deletePushCProfileFailure, (state, {error}) => ({...state, loading: false, error})),
);


