import {NgModule} from '@angular/core';
import {FireworkComponent} from "./firework.component";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared.module";

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [FireworkComponent],
  exports: [FireworkComponent],
})
export class FireworkModule {}
