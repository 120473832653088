import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as EProfileActions from '../actions/eProfile.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of, tap} from 'rxjs';
import {Store} from "@ngrx/store";
import {EProfileService} from "../apis/eProfile.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Injectable()
export class EProfileEffects {
  constructor(private actions$: Actions, private eProfileService: EProfileService,
              private store: Store, private toast: ToastrService, private router: Router) {
  }

  loadEProfile$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.loadEProfile),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.eProfileService.loadEProfile(id).pipe(
            map(data => EProfileActions.loadEProfileSuccess({data})),
            catchError(error => of(EProfileActions.loadEProfileFailure({error})))
          );
        })
      )
    }
  );

  getPushes$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.getPushes),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.eProfileService.getPushes(id).pipe(
            map(data => EProfileActions.getPushesSuccess({data})),
            catchError(error => of(EProfileActions.getPushesFailure({error})))
          );
        })
      )
    }
  );

  getJobOffers$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.getJobOffers),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.eProfileService.getJobOffers(id).pipe(
            map(data => EProfileActions.getJobOffersSuccess({data})),
            catchError(error => of(EProfileActions.getJobOffersFailure({error})))
          );
        })
      )
    }
  );

  delete$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EProfileActions.remove),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.eProfileService.remove(id).pipe(
            map(data => EProfileActions.removeSuccess({data})),
            catchError(error => of(EProfileActions.removeFailure({error})))
          );
        })
      )
    }
  );

  deleteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EProfileActions.removeSuccess),
        tap(() => {
          this.toast.success('Entreprise supprimé')
          this.router.navigate(['profile/enterprise/table/visible'])
        }),
      );
    },
    { dispatch: false }
  );
}
