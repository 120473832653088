import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as SourcingActions from '../actions/sourcing.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of, tap} from 'rxjs';
import {SourcingService} from "../apis/sourcing.service";
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";

@Injectable()
export class SourcingEffects {
  constructor(private actions$: Actions, private sourcingService: SourcingService, private toast: ToastrService,
              private store: Store) {
  }

  sourcing$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SourcingActions.loadSourcing),
        map((action: any) => action),
        mergeMap(() => {
          return this.sourcingService.load().pipe(
            map(data => SourcingActions.loadSourcingSuccess({data})),
            catchError(error => of(SourcingActions.loadSourcingFailure({error})))
          );
        })
      )
    }
  );

  currentSourcing$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SourcingActions.getCurrentSourcing),
        map((action: any) => action),
        mergeMap(() => {
          return this.sourcingService.current().pipe(
            map(data => SourcingActions.getCurrentSourcingSuccess({data})),
            catchError(error => of(SourcingActions.getCurrentSourcingFailure({error})))
          );
        })
      )
    }
  );

  getSourcing$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SourcingActions.getSourcing),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.sourcingService.get(id).pipe(
            map(data => SourcingActions.getSourcingSuccess({data})),
            catchError(error => of(SourcingActions.getSourcingFailure({error})))
          );
        })
      )
    }
  );

  updateJobOffer$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SourcingActions.updateJobOffer),
        map((action: any) => action),
        mergeMap(({id, jobOfferId, fromUserId, targetUserId}) => {
          return this.sourcingService.updateJobOffer(id, jobOfferId, fromUserId, targetUserId).pipe(
            map(data => SourcingActions.updateJobOfferSuccess({data})),
            catchError(error => of(SourcingActions.updateJobOfferFailure({error})))
          );
        })
      )
    }
  );

  updateJobOfferSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SourcingActions.updateJobOfferSuccess),
        tap(() => {
          this.toast.success('Sourcing modifié')
        }),
      );
    },
    { dispatch: false }
  );

  storeJobOffer$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SourcingActions.storeSourcing),
        map((action: any) => action),
        mergeMap(({duplicate}) => {
          return this.sourcingService.store(duplicate).pipe(
            map(data => SourcingActions.storeSourcingSuccess({data})),
            catchError(error => of(SourcingActions.storeSourcingFailure({error})))
          );
        })
      )
    }
  );

  storeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SourcingActions.storeSourcingSuccess),
        tap(() => {
          this.toast.success('Sourcing modifié')
        }),
      );
    },
    { dispatch: false }
  );
}
