import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as SubscriberActions from '../actions/subscriber.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of, tap} from 'rxjs';
import {SubscriberService} from "../apis/subscriber.service";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class SubscriberEffects {
  constructor(private actions$: Actions, private subscriberService: SubscriberService, private toast: ToastrService) {
  }

  loadSubscriber$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SubscriberActions.loadSubscriber),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.subscriberService.loadSubscriber(id).pipe(
            map(data => SubscriberActions.loadSubscriberSuccess({data})),
            catchError(error => of(SubscriberActions.loadSubscriberFailure({error})))
          );
        })
      )
    }
  );

  standby$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SubscriberActions.standby),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.subscriberService.standby(id).pipe(
            map(data => SubscriberActions.standbySuccess({data})),
            catchError(error => of(SubscriberActions.standbyFailure({error})))
          );
        })
      )
    }
  );

  standbySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriberActions.standbySuccess),
        tap(() => {
          this.toast.success('Profil en veille')
        }),
      );
    },
    { dispatch: false }
  );

  reconnect$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SubscriberActions.reconnect),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.subscriberService.reconnect(id).pipe(
            map(data => SubscriberActions.reconnectSuccess({data})),
            catchError(error => of(SubscriberActions.reconnectFailure({error})))
          );
        })
      )
    }
  );

  reconnectSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriberActions.reconnectSuccess),
        tap(() => {
          this.toast.success('Profil reconnecté')
        }),
      );
    },
    { dispatch: false }
  );
}
