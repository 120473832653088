import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {faBell, faBuilding, faGrip, faUser} from '@fortawesome/free-solid-svg-icons'
import {Router} from "@angular/router";
import {debounceTime, Subject, takeUntil} from "rxjs";
import {Store} from "@ngrx/store";
import * as SearchActions from '../../../core/actions/search.actions';
import * as SearchSelectors from '../../../core/selectors/search.selectors';
import {Actions, ofType} from "@ngrx/effects";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import * as UserSelectors from "../../../core/selectors/user.selectors";

declare var bootstrap: any


@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopBarComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() title: string = ''

  fa = {faBell, faGrip, faBuilding, faUser}

  @Input() toggleMenu: any

  @ViewChild('dropdown')
  dropdown: ElementRef

  searchInput: string

  searches$ = this.store.select(SearchSelectors.selectSearches)
  loading$ = this.store.select(SearchSelectors.loading)

  form = new FormGroup({
    search: new FormControl('', Validators.required),
  })

  ngUnsubscribe = new Subject<void>();
  user$ =  this.store.select(UserSelectors.selectUser).pipe(takeUntil(this.ngUnsubscribe))

  dropdownLoad: any

  constructor(private store: Store, private router: Router, private actions$: Actions) {

    this.actions$.pipe(
      ofType(SearchActions.loadSearchSuccess),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(action => {
      this.dropdownLoad.show()
    })
  }

  ngOnInit(): void {
    this.search.valueChanges.pipe(debounceTime(300), takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      if (this.search.value.length > 0) {
        this.store.dispatch(SearchActions.loadSearch({search: this.search.value}))
      } else {
        this.store.dispatch(SearchActions.loadSearchSuccess({data: []}))
      }
    })
  }

  ngAfterViewInit(): void {
    this.dropdownLoad = new bootstrap.Dropdown(this.dropdown.nativeElement)
  }

  logout(): void {
    this.router.navigate(['logout'])
  }

  get search(): AbstractControl {
    return this.form.get('search')!;
  }

  ngOnDestroy(): void {

    if (this.dropdownLoad) {
      this.dropdownLoad.dispose()
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
