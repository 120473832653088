<div class="offcanvas offcanvas-end"  tabindex="-1" #offCanvas>
  <ng-container *ngIf="push$ | async as push">
    <div class="offcanvas-header">
      <div class="d-flex justify-content-between gap-3 align-items-center">
        <h3 class="m-0">Sélection de candidats pour </h3>
        <div class="d-flex gap-3 align-items-center">
          <app-avatar [eProfile]="push.e_profile"></app-avatar>
          <div>{{push.e_profile.enterprise}}</div>
        </div>
        <div></div>
      </div>
    </div>
    <div class="offcanvas-body p-0">
      <app-table-push-cprofiles [push]="push"></app-table-push-cprofiles>

    </div>
    <div class="offcanvas-footer">
      <form [formGroup]="form">
        <div class="d-flex justify-content-between align-ite gap-5 align-items-center">
          <div>
            <div class="btn btn-danger" (click)="deletePush()">
              Supprimer
            </div>
          </div>
          <div class="flex-fill">
            <h4 class="">{{push.c_profiles.length}} candidat(s) sélectionné(s)</h4>
            <div>
              <ng-select id="jobOffer" [items]="jobOffers$ | async"
                         bindLabel="title"
                         bindValue="title"
                         appendTo="body"
                         [addTag]="true"
                         [editableSearchTerm]="true"
                         formControlName="title"
                         [closeOnSelect]="true"
                         [multiple]="false"
                         [clearable]="false"
                         addTagText="Ajouter un titre de poste"
                         placeholder="Sélectionnez ou ajoutez un titre poste"
                         [ngClass]="{'is-invalid' : (title.dirty || title.touched) && title.errors}">
                <ng-template ng-option-tmp let-job="item">
                  <div class="d-flex justify-content-between">
                    <div class="job-title">{{ job.title }}</div>
                    <span class="badge bg-success font-size-12" *ngIf="job.status == 0">Activé</span>
                    <span class="badge bg-danger font-size-12" *ngIf="job.status == 1">Désactivé</span>
                  </div>
                </ng-template>
              </ng-select>
              <div *ngIf="title?.invalid && (title?.dirty || title?.touched)" class="invalid-feedback">
                <div *ngIf="title?.hasError('required')">
                  Ce champ est obligatoire
                </div>
                <div *ngIf="title?.errors?.['serverError']">
                  {{title.errors?.['serverError']}}
                </div>
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-success" (click)="validatePush()">
              Valider
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
  <div class="position-absolute end-0" style="top:7%; left: -6.8rem;z-index: 9999; visibility: visible;width: max-content" *ngIf="push$ | async as push">
    <button class="btn btn-sm btn-outline-dark p-1 shadow-lg" (click)="toggle()">
      <div class="d-flex gap-2">
        <div>{{push.c_profiles.length}} candidat(s)</div>
        <fa-icon [icon]="fa.faPaperPlane"></fa-icon>
      </div>
    </button>
  </div>
</div>
