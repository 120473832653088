import {ChangeDetectorRef, Injectable} from "@angular/core";
import {Store} from "./store";
import {TagService} from "./tag.service";

@Injectable({
  providedIn: 'root'
})
export class TagsStore extends Store<[]|null> {

  constructor (private tagService: TagService, private ref: ChangeDetectorRef) {
    super(null);
    this.get()
  }

  get(): void {
    this.tagService.getAll().subscribe(data => {
      this.setState(data);
      this.ref.markForCheck()
    });
  }
}
