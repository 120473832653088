import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Store} from "@ngrx/store";
import {Subject, takeUntil} from "rxjs";
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {faTrashCan} from '@fortawesome/free-regular-svg-icons';
import * as PushActions from "../../../core/actions/push.actions";
import * as PushSelectors from "../../../core/selectors/push.selectors";
import * as EProfileSelectors from "../../../core/selectors/eProfile.selectors";
import {PushModel} from "../../../core/models/push.model";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import * as EProfileActions from "../../../core/actions/eProfile.actions";
import {Router} from "@angular/router";

declare var bootstrap: any

@Component({
  selector: 'app-offcanvas-push',
  templateUrl: './offcanvas-push.component.html',
  styleUrls: ['./offcanvas-push.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OffcanvasPushComponent implements OnInit, AfterViewInit ,OnDestroy {

  fa = {faPaperPlane, faTrashCan}

  @Input()
  refresh: any

  @Input()
  refreshParent: any

  @ViewChild('offCanvas')
  offCanvas: ElementRef

  form = new FormGroup({
    title: new FormControl<null|string>(null, Validators.required),
  })

  isOpened: boolean = false
  offCanvasLoad: any

  push$ = this.store.select(PushSelectors.selectCurrent)
  jobOffers$ = this.store.select(EProfileSelectors.selectJobOffers)

  push: PushModel

  ngUnsubscribe = new Subject<void>();

  constructor(private ref: ChangeDetectorRef, private store: Store, private router: Router) {
    this.store.dispatch(PushActions.loadPush())

    this.push$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((push) => {
      if (push) {
        this.push = push
        this.store.dispatch(EProfileActions.getJobOffers({id: this.push.e_profile.id}))
        this.form.patchValue({title: push.title })
      }
    })
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.offCanvasLoad = new bootstrap.Offcanvas(this.offCanvas.nativeElement)
    this.offCanvas.nativeElement.addEventListener('hidden.bs.offcanvas', () => {
      this.isOpened = false
      this.ref.markForCheck()
      if (this.refreshParent) {
        this.refreshParent()
      }
    })
    this.offCanvas.nativeElement.addEventListener('shown.bs.offcanvas', () => {
    })

    this.offCanvas.nativeElement.addEventListener('show.bs.offcanvas', () => {
      this.store.dispatch(PushActions.loadPush())
      this.isOpened = true
      this.ref.markForCheck()
    })
  }

  toggle() {
    this.offCanvasLoad.toggle()
  }

  validatePush() {
    if (this.form.valid) {
      this.store.dispatch(PushActions.updatePush({id: this.push.id, status: 'VALIDATED', title: this.title.value}))
      this.toggle()
      this.router.navigate(['/profile/enterprise/', this.push.e_profile.id], {fragment: 'pushes'})
    }
    this.form.markAllAsTouched()
    this.ref.markForCheck()
  }

  deletePush() {
    this.store.dispatch(PushActions.deletePush({id: this.push.id}))
    this.toggle()
  }

  get title(): AbstractControl {
    return this.form.get('title')!;
  }

  ngOnDestroy(): void {
    if (this.offCanvasLoad) {
      this.offCanvasLoad.hide()
    }

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
