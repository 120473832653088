import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";


const API_URL = environment.API_URL;
const PATH = '/eprofile/'

@Injectable({
  providedIn: 'root'
})
export class EProfileService {

  constructor(private http: HttpClient) {
  }

  loadEProfile(id: string) {
    return this.http.get<any>(API_URL + PATH + id)
  }

  getPushes(id: string) {
    return this.http.get<any>(API_URL + PATH + id + '/pushes')
  }

  getJobOffers(id: string) {
    return this.http.get<any>(API_URL + PATH + id + '/jobOffers')
  }

  remove(id: string) {
    return this.http.delete<any>(API_URL + PATH + id)
  }
}
