import {Login} from "../models/Auth";
import {Action, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {GetUser, UserStateModel} from "../models/User";
import {Store} from "@ngrx/store";
import * as UserActions from "../core/actions/user.actions";

@State<UserStateModel>({
  name: 'user',
  defaults: {
    first_name: null,
    last_name: null,
    user_name: null,
    email: null,
    groups: [],
  }
})

@Injectable()
export class UserState {

  constructor(private store: Store) {}

  @Action(GetUser)
  login(ctx: StateContext<UserStateModel>, action: Login) {
    this.store.dispatch(UserActions.loadUser())
  }
}
