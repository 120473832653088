import {createReducer, on} from '@ngrx/store';
import * as JobOfferAction from '../actions/jobOffer.actions'
import {JobOfferModel} from "../models/jobOffer.model";
import {CProfileModel} from "../models/cProfile.model";

export const PushFeatureKey = 'jobOffer';

export interface State {
  current: JobOfferModel | null,
  sourcing: CProfileModel[],
  matchMaking: CProfileModel[],
  loading: boolean,
  loadingMatchMaking: boolean,
  error: any
}

export const initialState: State = {
  current: null,
  matchMaking: [],
  sourcing: [],
  loading: false,
  loadingMatchMaking: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(JobOfferAction.updateStatus, (state) => ({...state, loading: true, error: null})),
  on(JobOfferAction.updateStatusSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(JobOfferAction.updateStatusFailure, (state, {error}) => ({...state, loading: false, error})),
  on(JobOfferAction.loadJobOffer, (state) => ({...state, current: null, loading: true, error: null})),
  on(JobOfferAction.loadJobOfferSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(JobOfferAction.loadJobOfferFailure, (state, {error}) => ({...state, loading: false, error})),
  on(JobOfferAction.getMatchMaking, (state) => ({...state, matchMaking: [], loadingMatchMaking: true, error: null})),
  on(JobOfferAction.getMatchMakingSuccess, (state, {data}) => ({
    ...state,
    matchMaking: data,
    loadingMatchMaking: false,
    error: null
  })),
  on(JobOfferAction.getMatchMakingFailure, (state, {error}) => ({...state, loadingMatchMaking: false, error})),
  on(JobOfferAction.getSourcing, (state) => ({...state, sourcing: [], loading: true, error: null})),
  on(JobOfferAction.getSourcingSuccess, (state, {data}) => ({
    ...state,
    sourcing: data,
    loading: false,
    error: null
  })),
  on(JobOfferAction.getSourcingFailure, (state, {error}) => ({...state, loading: false, error})),

);


