import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as CommentaryActions from '../actions/commentary.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from "@ngrx/store";
import {CommentaryService} from "../apis/commentary.service";

@Injectable()
export class CommentaryEffects {
  constructor(private actions$: Actions, private commentaryService: CommentaryService, private store: Store) {
  }

  loadCommentaries$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CommentaryActions.loadCommentaries),
        map((action: any) => action),
        mergeMap(({profile, profile_id}) => {
          return this.commentaryService.loadCommentaries(profile, profile_id).pipe(
            map(data => CommentaryActions.loadCommentariesSuccess({data})),
            catchError(error => of(CommentaryActions.loadCommentariesFailure({error})))
          );
        })
      )
    }
  );

  createCommentary$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CommentaryActions.createCommentary),
        map((action: any) => action),
        mergeMap(({profile, profile_id, comment, proposition_id}) => {
          return this.commentaryService.storeCommentary(profile, profile_id, comment, proposition_id).pipe(
            map(data => CommentaryActions.createCommentarySuccess({data})),
            catchError(error => of(CommentaryActions.createCommentaryFailure({error})))
          );
        })
      )
    }
  );

  updateCommentary$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CommentaryActions.updateCommentary),
        map((action: any) => action),
        mergeMap(({commentary_id, comment}) => {
          return this.commentaryService.updateCommentary(commentary_id, comment).pipe(
            map(data => CommentaryActions.updateCommentarySuccess({data})),
            catchError(error => of(CommentaryActions.updateCommentaryFailure({error})))
          );
        })
      )
    }
  );

  deleteCommentary$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(CommentaryActions.deleteCommentary),
        map((action: any) => action),
        mergeMap(({commentary_id}) => {
          return this.commentaryService.deleteCommentary(commentary_id).pipe(
            map(data => CommentaryActions.deleteCommentarySuccess({data})),
            catchError(error => of(CommentaryActions.deleteCommentaryFailure({error})))
          );
        })
      )
    }
  );
}
