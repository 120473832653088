import {NgModule} from '@angular/core';
import {OffcanvasPushComponent} from "./offcanvas-push.component";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared.module";
import {AvatarModule} from "../avatar/avatar.module";
import {TablePushCprofileModule} from "../table-push-cprofile/table-push-cprofile.module";
import {NgSelectModule} from "@ng-select/ng-select";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AvatarModule,
    TablePushCprofileModule,
    NgSelectModule,
  ],
  declarations: [OffcanvasPushComponent],
  exports: [OffcanvasPushComponent],
})
export class OffcanvasPushModule {}
