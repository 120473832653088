import {NgModule} from '@angular/core';
import {AvatarComponent} from "./avatar.component";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared.module";

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [AvatarComponent],
  exports: [AvatarComponent],
})
export class AvatarModule {}
