<div class="table-responsive">
  <table class="table align-middle">
    <thead class="fixed">
    <tr>
      <th class="col-sm-1 text-center">Id</th>
      <th class="col-sm-2 text-center">Candidat</th>
      <th class="col-sm-8 text-center">Détails</th>
      <th class="col-sm-1 text-center">Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let cProfile of push.c_profiles" [bgColor]="cProfile.subscriber">
      <td class="align-middle">
        <div class="d-flex flex-column gap-1 justify-content-center align-items-center">
          <div>{{cProfile.id }}</div>
        </div>
      </td>
      <td>
        <div class="d-flex flex-column gap-2 align-items-center">
          <div [Tooltip]="cProfile.subscriber.internal_comment">
            <ng-container *ngIf="cProfile.hits && cProfile.hits.length !== 0 && (!cProfile.propositions || cProfile.propositions.length === 0) && !cProfile.is_rejected">
              <app-avatar [subscriber]="cProfile.subscriber" [badge]="'Vu'"></app-avatar>
            </ng-container>
            <ng-container *ngIf="cProfile.propositions && cProfile.propositions.length !== 0 && !cProfile.is_rejected">
              <app-avatar [subscriber]="cProfile.subscriber" [badge]="'Contacté'"></app-avatar>
            </ng-container>
            <ng-container *ngIf="cProfile.is_rejected">
              <app-avatar [subscriber]="cProfile.subscriber" [badge]="'Rejeté'"></app-avatar>
            </ng-container>
            <ng-container *ngIf="(!cProfile.hits || cProfile.hits.length === 0) && (!cProfile.propositions || cProfile.propositions.length === 0) && !cProfile.is_rejected">
              <app-avatar [subscriber]="cProfile.subscriber"></app-avatar>
            </ng-container>
          </div>
          <a [routerLink]="['/profile/candidate', cProfile.id]" class="fw-medium text-center">{{cProfile.subscriber.fullname}}</a>
        </div>
      </td>
      <td class="p-0">
        <table class="table table-sm m-0">
          <thead class="fixed">
          <tr [bgColor]="cProfile.subscriber">
            <th class="col-5">jobs</th>
            <th class="col-2">salaire</th>
            <th class="col-5">skills</th>
          </tr>
          </thead>
          <tbody>
          <tr [bgColor]="cProfile.subscriber">
            <td>
              <div class="d-flex flex-column gap-1"></div>
              <div *ngFor="let job of cProfile.jobs; let isLast = last">{{job.title}}
              </div>
            </td>
            <td>
              <div class="d-flex flex-row" style="gap: 3px">
                <div>{{cProfile.salary_min}}</div>
                <div>-</div>
                <div>{{cProfile.salary_max}}</div>
              </div>
            </td>
            <td>
              <span *ngFor="let skill of cProfile.skills | filter:'is_favorite' : true" class="badge bg-success m-1">
                {{skill.title}}
              </span>
            </td>
          </tr>
          </tbody>

        </table>
        <table class="table table-sm m-0">
          <thead class="fixed">
          <tr [bgColor]="cProfile.subscriber">
            <th class="col-3">Experience</th>
            <th class="col-3">Remote</th>
            <th class="col-3">Anglais</th>
            <th class="col-3">Secteurs</th>
          </tr>
          </thead>
          <tbody>
          <tr [bgColor]="cProfile.subscriber">
            <td class="text-center">
              <span class="badge badge-soft-primary m-1">{{cProfile.experience_level}} ans</span>
            </td>
            <td>
              <ng-container *ngIf="cProfile.remote === 0">
                <span class="badge bg-primary font-size-12">Au Bureau</span>
              </ng-container>
              <ng-container *ngIf="cProfile.remote === 1">
                <span class="badge bg-primary font-size-12">Full Remote</span>
              </ng-container>
              <ng-container *ngIf="cProfile.remote === 2">
                <span class="badge bg-primary font-size-12">Hybride</span>
                <span class="badge badge-soft-primary font-size-12">min :{{cProfile.remote_min}}</span>
                <span class="badge badge-soft-primary font-size-12">max :{{cProfile.remote_max}}</span>
              </ng-container>
              <ng-container *ngIf="cProfile.remote === 3">
                <span class="badge bg-primary font-size-12">Peu Importe</span>
              </ng-container>
            </td>
            <td>{{cProfile.english_level}}</td>
            <td>
              <div class="d-flex flex-wrap gap-2">
                <div *ngFor="let sector of cProfile.sector_desired" class="badge bg-primary">
                  {{sector.title}}
                </div>
                <div *ngFor="let sector of cProfile.sector_undesired" class="badge bg-danger">
                  {{sector.title}}
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </td>
      <td class="align-middle text-center">
        <div (click)="removeCProfile(cProfile)" style="cursor: pointer">
          <fa-icon [icon]="fa.faTrashCan" class="text-danger"></fa-icon>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
