import {ChangeDetectorRef, Injectable} from "@angular/core";
import {Store} from "../../shared/data-access/store";
import {StatesService} from "./states.service";

@Injectable({
  providedIn: 'root',
})
export class StatesStore extends Store<[]|null> {

  constructor (private statesService: StatesService, private ref: ChangeDetectorRef) {
    super(null);
  }

  get(): void {
    this.statesService.getAll().subscribe(data => {
      this.setState(data);
      this.ref.markForCheck()
    });
  }
}
