import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromPushReducer from '../reducers/push.reducer';

export const getPushState = createFeatureSelector<fromPushReducer.State>('push');

export const loading = createSelector(getPushState, (state: fromPushReducer.State) => state.loading);

export const selectError = createSelector(getPushState, (state) => state.error);

export const selectCurrent = createSelector(getPushState, (state: fromPushReducer.State) => state.current);
