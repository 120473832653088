import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromEProfileReducer from '../reducers/eProfile.reducer';

export const getEProfileState = createFeatureSelector<fromEProfileReducer.State>('eProfile');

export const loading = createSelector(getEProfileState, (state: fromEProfileReducer.State) => state.loading);

export const selectError = createSelector(getEProfileState, (state) => state.error);

export const selectEProfile = createSelector(getEProfileState, (state: fromEProfileReducer.State) => state.eProfile);

export const selectPushes = createSelector(getEProfileState, (state: fromEProfileReducer.State) => state.pushes);

export const selectJobOffers = createSelector(getEProfileState, (state: fromEProfileReducer.State) => state.jobOffers);
