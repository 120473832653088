import {ChangeDetectorRef, Injectable} from "@angular/core";
import {Store} from "./store";
import {SkillService} from "./skill.service";

@Injectable({
  providedIn: 'root'
})
export class SkillsStore extends Store<[]|null> {
  constructor (private ref: ChangeDetectorRef, private skillService: SkillService) {
    super(null);
    this.get()
  }

  get(): void {
    this.skillService.getAll().subscribe(data => {
      this.setState(data);
      this.ref.markForCheck()
    });
  }

  addSkill(skillToAdd: string) {

    if (this.state) {
      if (typeof this.state.find((skill: any) => {
        return skill.id === skillToAdd
      }) === 'undefined') {
        let state = this.state
        // @ts-ignore
        state.push({id: skillToAdd, title: skillToAdd})
        this.setState(state)
      }
    }
  }
}
