import {createReducer, on} from '@ngrx/store';
import * as EProfileActions from '../actions/eProfile.actions'
import {EProfileModel} from "../models/eProfile.model";
import {PushModel} from "../models/push.model";
import {JobOfferModel} from "../models/jobOffer.model";

export const EProfileFeatureKey = 'eProfile';

export interface State {
  eProfile: EProfileModel | null,
  pushes: PushModel[],
  jobOffers: JobOfferModel[],
  loading: boolean,
  error: any
}

export const initialState: State = {
  eProfile: null,
  pushes: [],
  jobOffers: [],
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(EProfileActions.loadEProfile, (state) => ({...state, loading: true, error: null})),
  on(EProfileActions.loadEProfileSuccess, (state, {data}) => ({
    ...state,
    eProfile: data,
    loading: false,
    error: null
  })),
  on(EProfileActions.loadEProfileFailure, (state, {error}) => ({...state, loading: false, error})),
  on(EProfileActions.getPushes, (state) => ({...state, loading: true, error: null})),
  on(EProfileActions.getPushesSuccess, (state, {data}) => ({
    ...state,
    pushes: data,
    loading: false,
    error: null
  })),
  on(EProfileActions.getPushesFailure, (state, {error}) => ({...state, loading: false, error})),
  on(EProfileActions.getJobOffers, (state) => ({...state, loading: true, error: null})),
  on(EProfileActions.getJobOffersSuccess, (state, {data}) => ({
    ...state,
    jobOffers: data,
    loading: false,
    error: null
  })),
  on(EProfileActions.getJobOffersFailure, (state, {error}) => ({...state, loading: false, error})),
  on(EProfileActions.remove, (state) => ({...state, loading: true, error: null})),
  on(EProfileActions.removeSuccess, (state, {data}) => ({
    ...state,
    current: null,
    pushes: [],
    jobOffers: [],
    loading: false,
    error: null
  })),
  on(EProfileActions.removeFailure, (state, {error}) => ({...state, loading: false, error})),
);


