import {createReducer, on} from '@ngrx/store';
import * as CommentaryActions from '../actions/commentary.actions'
import {CommentaryModel} from "../models/commentary.model";

export const CommentaryFeatureKey = 'commentary';

export interface State {
  commentaries: CommentaryModel[],
  loading: boolean,
  error: any

}

export const initialState: State = {
  commentaries: [],
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(CommentaryActions.loadCommentaries, (state) => ({...state, loading: true, error: null})),
  on(CommentaryActions.loadCommentariesSuccess, (state, {data}) => ({
    ...state,
    commentaries: data,
    loading: false,
    error: null
  })),
  on(CommentaryActions.loadCommentariesFailure, (state, {error}) => ({...state, loading: false, error})),
);


