import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from "@angular/router";
import {TablePushCprofileComponent} from "./table-push-cprofile.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {AvatarModule} from "../avatar/avatar.module";
import {SharedModule} from "../../shared.module";

@NgModule({
  imports: [CommonModule, RouterModule, FontAwesomeModule, AvatarModule, SharedModule],
  declarations: [TablePushCprofileComponent],
  exports: [TablePushCprofileComponent],
})
export class TablePushCprofileModule {}
