import {createAction, props} from '@ngrx/store';
import {PushModel} from "../models/push.model";
import {JobOfferModel} from "../models/jobOffer.model";

export const loadEProfile = createAction(
  '[EProfile] Load EProfile',
  props<{ id: string }>()
);

export const loadEProfileSuccess = createAction(
  '[EProfile] Load EProfile Success',
  props<{ data: any }>()
);

export const loadEProfileFailure = createAction(
  '[EProfile] Load EProfile Failure',
  props<{ error: any }>()
);

export const getPushes = createAction(
  '[EProfile] Get Pushes',
  props<{ id: string }>()
);

export const getPushesSuccess = createAction(
  '[EProfile] Get Pushes Success',
  props<{ data: PushModel[] }>()
);

export const getPushesFailure = createAction(
  '[EProfile] Get Pushes Failure',
  props<{ error: any }>()
);

export const getJobOffers = createAction(
  '[EProfile] Get Pushes EProfile',
  props<{ id: string }>()
);

export const getJobOffersSuccess = createAction(
  '[EProfile] Get JobOffers Success',
  props<{ data: JobOfferModel[] }>()
);

export const getJobOffersFailure = createAction(
  '[EProfile] Get JobOffers Failure',
  props<{ error: any }>()
);

export const remove = createAction(
  '[EProfile] Delete remove',
  props<{ id: string }>()
);

export const removeSuccess = createAction(
  '[EProfile] Delete remove Success',
  props<{ data: JobOfferModel[] }>()
);

export const removeFailure = createAction(
  '[EProfile] Delete remove Failure',
  props<{ error: any }>()
);
