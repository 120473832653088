import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import tippy from 'tippy.js';

@Directive({
  selector: '[Tooltip]'
})
export class TooltipDirective implements OnInit {

  @Input('Tooltip')
  Tooltip: string | null = '';

  constructor(private el: ElementRef) {
  }

  decodeHTMLEntities(text: any) {
    let textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    const html = textArea.value
    textArea.remove()
    return html;
  }
  ngOnInit(): void {
    if (this.Tooltip) {
      tippy(this.el.nativeElement, {
        maxWidth: 500,
        content: this.decodeHTMLEntities(this.Tooltip),
        theme: 'light',
        allowHTML: true,
      })
    }
  }
}
