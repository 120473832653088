import { NgModule } from '@angular/core';
import { SimplebarAngularComponent } from './Simplebar-angular.component';
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [SimplebarAngularComponent],
  imports: [CommonModule],
  exports: [SimplebarAngularComponent]
})
export class SimplebarAngularModule { }
