<div *ngIf="isAuthenticate && !isError; else elseAuthenticate" id="layout-wrapper" aria-live="polite">
  <app-top-bar [title]="title" [toggleMenu]="toggleMenu"></app-top-bar>
  <app-sidebar [toggleMenu]="toggleMenu"></app-sidebar>

  <div class="main-content">
    <app-offcanvas-push></app-offcanvas-push>
    <div class="page-content">
      <div class="container-fluid" >
        <app-firework *ngIf="displayFirework === true"></app-firework>
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
<ng-template #elseAuthenticate >
  <div id="app">
    <router-outlet></router-outlet>
  </div>
</ng-template>
