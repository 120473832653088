export interface UserStateModel {
  first_name: string | null,
  last_name: string | null,
  user_name: string | null,
  email: string | null
  groups: []
}

export class GetUser {
  static readonly type = '[User] get';
  constructor() {}
}
