import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {SubscriberModel} from "../models/subscriber.model";


const API_URL = environment.API_URL;
const PATH = '/cprofile/'

@Injectable({
  providedIn: 'root'
})
export class CProfileService {

  constructor(private http: HttpClient) {
  }

  loadCProfile(id: string) {
    return this.http.get<any>(API_URL + PATH + id)
  }

  getHitStats(id: string) {
    return this.http.get<any>(API_URL + PATH + id + '/hitStats')
  }

  getMatchMaking(id: any) {
    return this.http.get<any>(API_URL + PATH + id + '/matchMaking')
  }

  updateAvailability(id: any, availability_status: any, availability_date: any) {
    return this.http.patch<any>(API_URL + PATH + id + '/availability/', {availability_status, availability_date})
  }

  updateExperienceLevel(id: any) {
    return this.http.patch<any>(API_URL + PATH + id + '/experienceLevel/', {})
  }

  updateRecommendationVisibility(id: any, recommendation_id: any, visibility: boolean) {
    return this.http.patch<any>(API_URL + PATH + id + '/recommendationVisibility/', {recommendation_id, visibility})
  }

  getEmails(id: string) {
    return this.http.get<any>(API_URL + PATH + id + '/emails')
  }

  sendEmail(id: string, emailType: string) {
    return this.http.post<any>(API_URL + PATH + id + '/email', {type: emailType})
  }

  importLinkedin(id: string, url: string, picture: boolean, experiences: boolean, educations: boolean,
                 certifications: boolean, localisation: boolean, recommendations: boolean, skills: boolean, english: boolean,
                 languages: boolean): Observable<any> {
    return this.http.patch<SubscriberModel>(API_URL + PATH + id + '/linkedin', {
      url, picture, experiences, educations, certifications, localisation,
      recommendations, skills, english, languages
    })
  }
}
