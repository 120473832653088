import {AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'app-firework',
  templateUrl: './firework.component.html',
  styleUrls: ['./firework.component.scss']
})
export class FireworkComponent implements OnInit, AfterViewInit {

  @ViewChild('canvasElement')
  canvasElement: ElementRef
  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D | null;
  private cw: number;
  private ch: number;
  private fireworks: Firework[] = [];
  private particles: Particle[] = [];
  private hue = 120;
  private limiterTotal = 5;
  private limiterTick = 0;
  private timerTotal = 80;
  private timerTick = 50;
  private mousedown = false;
  private mx: number;
  private my: number;

  constructor(private elementRef: ElementRef, private ngZone: NgZone) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.canvas = this.canvasElement.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    this.cw = window.innerWidth;
    this.ch = window.innerHeight;
    this.canvas.width = this.cw;
    this.canvas.height = this.ch;
    this.ngZone.runOutsideAngular(() => this.animate());
    this.canvas.addEventListener('mousemove', (e) => this.mouseMove(e));
    this.canvas.addEventListener('mousedown', () => this.mouseDown());
    this.canvas.addEventListener('mouseup', () => this.mouseUp());
    this.canvas.addEventListener('touchmove', (e) => this.touchMove(e));
    this.canvas.addEventListener('touchstart', (e) => this.touchStart(e));
    this.canvas.addEventListener('touchend', (e) => this.touchEnd());
  }

  ngOnDestroy(): void {
    // Clean up any event listeners or resources here
  }

  private cleanUpFireworks(): void {
    this.fireworks = this.fireworks.filter(firework => !firework.dead);
  }

  private mouseMove(e: MouseEvent): void {
    this.mx = e.pageX - this.canvas.offsetLeft;
    this.my = e.pageY - this.canvas.offsetTop;
  }

  private mouseDown(): void {
    this.mousedown = true;
  }

  private mouseUp(): void {
    this.mousedown = false;
  }

  private touchMove(e: TouchEvent): void {
    const touch = e.touches[0];
    if (touch) {
      this.mx = touch.pageX - this.canvas.offsetLeft;
      this.my = touch.pageY - this.canvas.offsetTop;
    }
    e.preventDefault();
  }

  private touchStart(e: TouchEvent): void {
    this.mousedown = true;
    const touch = e.touches[0];
    if (touch) {
      this.mx = touch.pageX - this.canvas.offsetLeft;
      this.my = touch.pageY - this.canvas.offsetTop;
    }
    e.preventDefault();
  }

  private touchEnd(): void {
    this.mousedown = false;
  }

  private random(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  // private calculateDistance(p1x: number, p1y: number, p2x: number, p2y: number): number {
  //   const xDistance = p1x - p2x;
  //   const yDistance = p1y - p2y;
  //   return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
  // }
  //
  // private createParticles(x: number, y: number): void {
  //   let particleCount = 30;
  //   while (particleCount--) {
  //     if (this.ctx) {
  //       this.particles.push(new Particle(x, y, this.ctx, this.particles));
  //     }
  //   }
  // }

  private animate(): void {

    this.cleanUpFireworks();
    requestAnimationFrame(() => {
      this.animate()
    });
    if (!this.ctx) {
      return
    }
    this.ctx.globalCompositeOperation = 'destination-out';
    this.ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    this.ctx.fillRect(0, 0, this.cw, this.ch);
    this.ctx.globalCompositeOperation = 'lighter';

    let i = this.fireworks.length;
    while (i--) {
      this.fireworks[i].draw();
      this.fireworks[i].update(i);
    }

    i = this.particles.length;
    while (i--) {
      this.particles[i].draw();
      this.particles[i].update(i);
    }

    if (this.timerTick >= this.timerTotal) {
      if (!this.mousedown) {
        for (let i = 0; i < 10; i++) { // Augmentez le nombre de feux d'artifice générés
          this.fireworks.push(new Firework(this.cw / 2, this.ch, this.random(0, this.cw), this.random(0, this.ch / 2), this.ctx, this.hue, this.particles));
        }
        this.timerTick = 0;
      }
    } else {
      this.timerTick++;
    }

    if (this.limiterTick >= this.limiterTotal) {
      if (this.mousedown) {
        this.fireworks.push(new Firework(this.cw / 2, this.ch, this.mx, this.my, this.ctx, this.hue, this.particles));
        this.limiterTick = 0;
      }
    } else {
      this.limiterTick++;
    }
  }
}

class Firework {
  private x: number;
  private y: number;
  private sx: number;
  private sy: number;
  private tx: number;
  private ty: number;
  private distanceToTarget: number;
  private distanceTraveled: number;
  private coordinates: [number, number][] = [];
  private coordinateCount = 3;
  private angle: number;
  private speed = 2;
  private acceleration = 1.05;
  private brightness: number;
  private targetRadius = 1;
  public dead = false;
  private ctx: CanvasRenderingContext2D;
  private hue: number = 0
  private particles: Particle[] = [];

  constructor(sx: number, sy: number, tx: number, ty: number, ctx: CanvasRenderingContext2D, hue: number, particles: Particle[]) {
    this.ctx = ctx;
    this.x = sx;
    this.y = sy;
    this.sx = sx;
    this.sy = sy;
    this.tx = tx;
    this.ty = ty;
    this.ctx = ctx
    this.hue = hue
    this.particles = particles
    this.distanceToTarget = this.calculateDistance(sx, sy, tx, ty);
    this.distanceTraveled = 0;

    while (this.coordinateCount--) {
      this.coordinates.push([this.x, this.y]);
    }

    this.angle = Math.atan2(ty - sy, tx - sx);
    this.brightness = this.random(50, 70);
  }

  draw(): void {
    this.ctx.beginPath();
    this.ctx.moveTo(this.coordinates[this.coordinates.length - 1][0], this.coordinates[this.coordinates.length - 1][1]);
    this.ctx.lineTo(this.x, this.y);
    this.ctx.strokeStyle = 'hsl(' + this.hue + ', 100%, ' + this.brightness + '%';
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.arc(this.tx, this.ty, this.targetRadius, 0, Math.PI * 2);
    this.ctx.stroke();
  }

  update(index: number): void {
    this.coordinates.pop();
    this.coordinates.unshift([this.x, this.y]);

    if (this.targetRadius < 8) {
      this.targetRadius += 0.3;
    } else {
      this.targetRadius = 1;
    }

    this.speed *= this.acceleration;

    const vx = Math.cos(this.angle) * this.speed;
    const vy = Math.sin(this.angle) * this.speed;

    this.distanceTraveled = this.calculateDistance(this.sx, this.sy, this.x + vx, this.y + vy);

    if (this.distanceTraveled >= this.distanceToTarget) {
      this.createParticles(this.tx, this.ty);
      this.dead = true;
    } else {
      this.x += vx;
      this.y += vy;
    }
  }

  private calculateDistance(p1x: number, p1y: number, p2x: number, p2y: number): number {
    const xDistance = p1x - p2x;
    const yDistance = p1y - p2y;
    return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
  }

  private random(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  private createParticles(x: number, y: number): void {
    let particleCount = 30;
    while (particleCount-- && particleCount > 0) {
      this.particles.push(new Particle(x, y, this.ctx, this.particles));
    }
  }
}
export class Particle {
  private x: number;
  private y: number;
  private coordinates: [number, number][] = [];
  private coordinateCount = 5;
  private angle: number;
  private speed: number;
  private friction: number;
  private gravity: number;
  private hue: number;
  private brightness: number;
  private alpha: number;
  private decay: number;
  private particles: Particle[] = [];
  private ctx: CanvasRenderingContext2D;


  constructor(x: number, y: number, ctx: CanvasRenderingContext2D, particles: Particle[]) {
    this.x = x;
    this.y = y;
    this.angle = this.random(0, Math.PI * 2);
    this.speed = this.random(1, 10);
    this.friction = 0.95;
    this.gravity = 1;
    this.hue = this.random(0, 360);
    this.brightness = this.random(50, 80);
    this.alpha = 1;
    this.decay = this.random(0.015, 0.03);
    this.particles = particles
    this.ctx = ctx

    while (this.coordinateCount--) {
      this.coordinates.push([this.x, this.y]);
    }
  }

  update(index: number): void {
    this.coordinates.pop();
    this.coordinates.unshift([this.x, this.y]);
    this.speed *= this.friction;
    this.x += Math.cos(this.angle) * this.speed;
    this.y += Math.sin(this.angle) * this.speed + this.gravity;
    this.alpha -= this.decay;

    if (this.alpha <= this.decay) {
      this.ctx.globalCompositeOperation = 'destination-out';
      this.coordinates.pop();
      this.ctx.beginPath();
      this.ctx.moveTo(this.coordinates[this.coordinates.length - 1][0], this.coordinates[this.coordinates.length - 1][1]);
      this.ctx.lineTo(this.x, this.y);
      this.ctx.stroke();
      this.ctx.globalCompositeOperation = 'lighter';
      this.particles.splice(index, 1);
    }
  }

  private random(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  draw(): void {
    this.ctx.beginPath();
    this.ctx.moveTo(this.coordinates[this.coordinates.length - 1][0], this.coordinates[this.coordinates.length - 1][1]);
    this.ctx.lineTo(this.x, this.y);
    this.ctx.strokeStyle = `hsla(${this.hue}, 100%, ${this.brightness}%, ${this.alpha})`;
    this.ctx.stroke();
  }
}
