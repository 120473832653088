import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {PushModel} from "../models/push.model";


const API_URL = environment.API_URL;
const PATH = '/push/'

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(private http: HttpClient) {
  }

  load() {
    return this.http.get<PushModel>(API_URL + PATH)
  }

  store(e_profile_id: string) {
    return this.http.post<PushModel>(API_URL + PATH, {e_profile_id})
  }

  update(id: string, status: string, title : string | null) {
    return this.http.patch<PushModel>(API_URL + PATH + id, {status, title: title})
  }

  delete(id: string) {
    return this.http.delete<any>(API_URL + PATH + id)
  }

  storeCandidate(id: string, c_profile_id: string) {
    return this.http.post<PushModel>(API_URL + PATH + id + '/cProfile', {c_profile_id})
  }

  deleteCandidate(id: string, c_profile_id: string) {
    return this.http.delete<PushModel>(API_URL + PATH + id + '/cProfile/' + c_profile_id)
  }
}
