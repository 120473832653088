import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {TokenService} from './token.service';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from '../login/data-access/auth.service';
import {AuthState} from "../state/auth.state";
import {Store} from '@ngxs/store';
import {ToastrService} from "ngx-toastr";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private access_token: string | null;
  private refresh_token: string | null;

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService,
    private store: Store, private toast: ToastrService) {

    this.store.select(AuthState).subscribe(authState => {
      this.access_token = authState.access_token
      this.refresh_token = authState.refresh_token
    })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {

    if (this.access_token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.access_token
        }
      });
    }

    if (!request.headers.has('Content-Type')) {
      let contentType = request.detectContentTypeHeader()
      if (contentType) {
        request = request.clone({
          setHeaders: {
            'Content-Type': contentType
          }
        });
      }
    }

    request = request.clone({
      setHeaders: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    });

    request = request.clone({
      headers: request.headers
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
            this.toast.error(error.error.message, 'Un petit problème')
            break
          case 401:
            if (error.error.error === 'invalid_token') {
              this.authService.refreshToken({refresh_token: this.refresh_token})
                .subscribe(() => {
                  location.reload();
                });
            } else {
              this.router.navigate(['login']).then(_ => console.log('redirect to login'));
            }
            break
          case 404:
            this.router.navigate(['404'], { replaceUrl: true, skipLocationChange: true });
            break
          case 500:
            this.router.navigate(['500'], { replaceUrl: true, skipLocationChange: true });
            break
        }
        return throwError(error);
      }));
  }
}
