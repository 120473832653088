<ng-container *ngIf="subscriber">
  <div class="d-flex justify-content-center align-items-center flex-column">
    <div class="avatar-{{size}}">
      <img *ngIf="subscriber.avatar" class="img-thumbnail rounded-circle" [src]="('' | environment).DATA_URL + subscriber.avatar"/>
      <span *ngIf="!subscriber.avatar" class="font-size-{{size}} img-thumbnail rounded-circle avatar-title bg-warning text-white" >{{subscriber.last_name[0]}}{{subscriber.first_name[0]}}</span>
    </div>
    <div style="margin-top: -15px" *ngIf="badge" [ngSwitch]="badge">
      <div *ngSwitchCase="'Vu'" class="badge bg-purple text-center" >{{badge}}</div>
      <div *ngSwitchCase="'Contacté'" class="badge bg-primary text-center text-white" style="background-color: var(--bs-primary) !important;" >{{badge}}</div>
      <div *ngSwitchCase="'Rejeté'" class="badge bg-danger text-center text-white">{{badge}}</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="eProfile">
  <div class="d-flex justify-content-center align-items-center flex-column enterprise">
    <div class="avatar-{{size}}">
      <img *ngIf="eProfile.avatar" class="rounded-circle" [src]="('' | environment).DATA_URL + eProfile.avatar"/>
      <span *ngIf="!eProfile.avatar" class="font-size-{{size}} img-thumbnail rounded-circle avatar-title" >{{eProfile.enterprise | uppercase}}</span>
    </div>
  </div>
</ng-container>
