import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as PushActions from '../actions/push.actions';
import * as PushSelectors from '../selectors/push.selectors';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {of, tap} from 'rxjs';
import {PushService} from "../apis/push.service";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class PushEffects {
  constructor(private actions$: Actions, private pushService: PushService, private store: Store,
              private toast: ToastrService) {
  }

  load$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PushActions.loadPush),
        map((action: any) => action),
        mergeMap(() => {
          return this.pushService.load().pipe(
            map(data => PushActions.loadPushSuccess({data})),
            catchError(error => of(PushActions.loadPushFailure({error})))
          );
        })
      )
    }
  );

  store$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PushActions.storePush),
        map((action: any) => action),
        mergeMap(({e_profile_id}) => {
          return this.pushService.store(e_profile_id).pipe(
            map(data => {
              this.toast.success('Session de push lancée', 'Confirmation')
              return PushActions.storePushSuccess({data})}),
            catchError(error => of(PushActions.storePushFailure({error})))
          );
        })
      )
    }
  );

  update$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PushActions.updatePush),
        map((action: any) => action),
        mergeMap(({id, status, title}) => {
          return this.pushService.update(id, status, title).pipe(
            map(data => {
              this.store.dispatch(PushActions.loadPush())
              if (data.status === 'VALIDATED') {
                this.toast.success('Session de push terminée', 'Confirmation')
              } else {
                this.toast.success('Edition de push commencée', 'Confirmation')
              }
              return PushActions.updatePushSuccess({data})
            }),
            catchError(error => of(PushActions.updatePushFailure({error})))
          );
        })
      )
    }
  );

  delete$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PushActions.deletePush),
        map((action: any) => action),
        mergeMap(({id}) => {
          return this.pushService.delete(id).pipe(
            map(data => {
              this.store.dispatch(PushActions.loadPush())
              this.toast.success('Push supprimé', 'Confirmation')
              return PushActions.deletePushSuccess({data})}),
            catchError(error => of(PushActions.deletePushFailure({error})))
          );
        })
      )
    }
  );

  storeCProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PushActions.storePushCProfile),
      withLatestFrom(this.store.select(PushSelectors.selectCurrent)),
      mergeMap(([action, currentState]) => {
        return this.pushService.storeCandidate(currentState?.id ?? '', action.c_profile_id ?? '').pipe(
          map(data => {
            this.toast.success('Candidat ajouté au push', 'Confirmation')
            return PushActions.storePushCProfileSuccess({data})
          }),
          catchError(error => of(PushActions.storePushCProfileFailure({error})))
        );
      })
    );
  });

  deleteCProfile$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PushActions.deletePushCProfile),
        map((action: any) => action),
        mergeMap(({id, c_profile_id}) => {
          return this.pushService.deleteCandidate(id, c_profile_id).pipe(
            map(data => {
              this.toast.success('Candidat supprimé du push', 'Confirmation')
              return PushActions.deletePushCProfileSuccess({data})}),
            catchError(error => of(PushActions.deletePushCProfileFailure({error})))
          );
        })
      )
    }
  );
}
