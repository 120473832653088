import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'min'
})
export class MinPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (!Array.isArray(value) || value.length === 0) {
      return value;
    }

    value.sort((a, b) => a - b);
    return value[0];
  }
}

@Pipe({
  name: 'max'
})
export class MaxPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (!Array.isArray(value) || value.length === 0) {
      return value;
    }

    value.sort((a, b) => b - a);
    return value[0];
  }
}
