import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


Sentry.init({
  dsn: "https://e7deb8f348b38fc1eab3ecbb1340ee10@o1294169.ingest.us.sentry.io/4507809788919808",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [  environment.APP_URL],
  enabled: environment.NAME !== 'dev',
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
