import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as SearchActions from '../actions/search.actions';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {SearchService} from "../apis/search.service";

@Injectable()
export class SearchEffects {
  constructor(private actions$: Actions, private searchService: SearchService) {
  }

  search$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SearchActions.loadSearch),
        switchMap(({search}) => {
          return this.searchService.search(search).pipe(
            map(data => SearchActions.loadSearchSuccess({data})),
            catchError(error => of(SearchActions.loadSearchFailure({error})))
          );
        })
      )
    }
  );
}
