import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as EducationActions from '../actions/education.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from "@ngrx/store";
import {EducationService} from "../apis/education.service";

@Injectable()
export class EducationEffects {
  constructor(private actions$: Actions, private educationService: EducationService, private store: Store) {
  }

  loadSchools$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(EducationActions.loadSchools),
        map((action: any) => action),
        mergeMap(({}) => {
          return this.educationService.loadSchools().pipe(
            map(data => EducationActions.loadSchoolsSuccess({data})),
            catchError(error => of(EducationActions.loadSchoolsFailure({error})))
          );
        })
      )
    }
  );
}
