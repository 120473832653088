import {createReducer, on} from '@ngrx/store';
import * as SourcingActions from '../actions/sourcing.actions'
import {SourcingModel} from "../models/sourcing.model";

export const SourcingFeatureKey = 'sourcing';

export interface State {
  current: SourcingModel | null,
  all: SourcingModel[],
  loading: boolean,
  error: any
}

export const initialState: State = {
  current: null,
  all: [],
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(SourcingActions.loadSourcing, (state) => ({...state, loading: true, error: null})),
  on(SourcingActions.loadSourcingSuccess, (state, {data}) => ({
    ...state,
    all: data,
    loading: false,
    error: null
  })),
  on(SourcingActions.loadSourcingFailure, (state, {error}) => ({...state, loading: false, error})),
  on(SourcingActions.getCurrentSourcing, (state) => ({...state, loading: true, error: null})),
  on(SourcingActions.getCurrentSourcingSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(SourcingActions.getCurrentSourcingFailure, (state, {error}) => ({...state, loading: false, error})),
  on(SourcingActions.storeSourcing, (state) => ({...state, loading: true, error: null})),
  on(SourcingActions.storeSourcingSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(SourcingActions.storeSourcingFailure, (state, {error}) => ({...state, loading: false, error})),
  on(SourcingActions.getSourcing, (state) => ({...state, current: null, loading: true, error: null})),
  on(SourcingActions.getSourcingSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(SourcingActions.getSourcingFailure, (state, {error}) => ({...state, loading: false, error})),
);


