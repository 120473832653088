import {createReducer, on} from '@ngrx/store';
import * as SubscriberActions from '../actions/subscriber.actions'
import {SubscriberModel} from "../models/subscriber.model";

export const PropositionFeatureKey = 'subscriber';

export interface State {
  current: SubscriberModel | null,
  loading: boolean,
  error: any

}

export const initialState: State = {
  current: null,
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(SubscriberActions.loadSubscriber, (state) => ({...state, loading: true, error: null})),
  on(SubscriberActions.loadSubscriberSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(SubscriberActions.loadSubscriberFailure, (state, {error}) => ({...state, loading: false, error})),
  on(SubscriberActions.standby, (state) => ({...state, loading: true, error: null})),
  on(SubscriberActions.standbySuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(SubscriberActions.standbyFailure, (state, {error}) => ({...state, loading: false, error})),
  on(SubscriberActions.reconnect, (state) => ({...state, loading: true, error: null})),
  on(SubscriberActions.reconnectSuccess, (state, {data}) => ({
    ...state,
    current: data,
    loading: false,
    error: null
  })),
  on(SubscriberActions.reconnectFailure, (state, {error}) => ({...state, loading: false, error})),
);


