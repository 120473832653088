import {createAction, props} from '@ngrx/store';
import {SourcingModel} from "../models/sourcing.model";

export const loadSourcing = createAction(
  '[Sourcing] Load Sourcing');

export const loadSourcingSuccess = createAction(
  '[Sourcing] Load Sourcing Success',
  props<{ data: SourcingModel[] }>()
);

export const loadSourcingFailure = createAction(
  '[Sourcing] Load Sourcing Failure',
  props<{ error: any }>()
);

export const getCurrentSourcing = createAction(
  '[Sourcing] Get Current Sourcing');

export const getCurrentSourcingSuccess = createAction(
  '[Sourcing] Get Current Sourcing Success',
  props<{ data: SourcingModel }>()
);

export const getCurrentSourcingFailure = createAction(
  '[Sourcing] Get Current Sourcing Failure',
  props<{ error: any }>()
);

export const getSourcing = createAction(
  '[Sourcing] Get Sourcing',
  props<{id: string}>()
)

export const getSourcingSuccess = createAction(
  '[Sourcing] Get Sourcing Success',
  props<{ data: SourcingModel }>()
);

export const getSourcingFailure = createAction(
  '[Sourcing] Get Sourcing Failure',
  props<{ error: any }>()
);

export const storeSourcing = createAction(
  '[Sourcing] Store Sourcing',
  props<{duplicate?: boolean}>()
);

export const storeSourcingSuccess = createAction(
  '[Sourcing] Store Sourcing Success',
  props<{ data: any }>()
);

export const storeSourcingFailure = createAction(
  '[Sourcing] Store Sourcing Failure',
  props<{ error: any }>()
);

export const updateJobOffer = createAction(
  '[Sourcing] Update JobOffer',
  props<{id: string, jobOfferId: string, fromUserId: string | null, targetUserId: string | null}>()
);

export const updateJobOfferSuccess = createAction(
  '[Sourcing] Update JobOffer Success',
  props<{ data: any }>()
);

export const updateJobOfferFailure = createAction(
  '[Sourcing] Update JobOffer Failure',
  props<{ error: any }>()
);
