import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  @Input()
  subscriber: any = null

  @Input()
  size: string = 'md'

  @Input()
  eProfile: any = null

  @Input()
  badge: string|null = null

  constructor() {}

  ngOnInit() {
  }
}
