import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], field : string, value : any, exclude: boolean = false): any[] {
    if (!items) return [];
    if (typeof value !== 'boolean' && value !== null && ((!value && value !== 0) || value.length == 0)) return items;
    return items.filter(it => {
      const fieldValue = this.getFieldValue(it, field);
      if (!exclude) {
        return fieldValue == value
      } else {
        return fieldValue != value
      }
    });
  }

  // Helper method to access nested properties
  private getFieldValue(item: any, field: string) {
    return field.split('.').reduce((obj, key) => obj && obj[key], item);
  }
}
