import { Pipe, PipeTransform } from '@angular/core';
import {UserStateModel} from "../../models/User";
import {UserModel} from "../../core/models/user.model";

@Pipe({
  name: 'group'
})
export class GroupPipe implements PipeTransform {

  transform(user: UserStateModel | UserModel, groupId: any): any {
    return user && user.groups.map((group: any) => group.id).includes(groupId)
  }
}
