import {ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {CProfileModel} from "../../../core/models/cProfile.model";
import * as PushActions from "../../../core/actions/push.actions";
import {Store} from "@ngrx/store";
import {PushModel} from "../../../core/models/push.model";

@Component({
  selector: 'app-table-push-cprofiles',
  templateUrl: './table-push-cprofile.component.html',
  styleUrls: ['./table-push-cprofile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TablePushCprofileComponent implements OnInit {

  fa = {faTrashCan}

  @Input()
  push: PushModel

  constructor(private ref: ChangeDetectorRef, private store: Store) {}

  ngOnInit(): void {
  }

  removeCProfile(cProfile: CProfileModel) {
    this.store.dispatch(PushActions.deletePushCProfile({id: this.push.id, c_profile_id: cProfile.id}))
  }
}
