import {AuthStateModel, Login, Logout} from "../models/Auth";
import {AuthService} from "../login/data-access/auth.service";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {pipe, tap} from "rxjs";

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    access_token: null,
    refresh_token: null,
  }
})
@Injectable()
export class AuthState {
  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.access_token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.access_token;
  }

  constructor(private authService: AuthService) {}

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.authService.login(action.payload.email, action.payload.password).pipe(
      tap((result) => {
        ctx.patchState({
          access_token: result.access_token,
          refresh_token: result.access_token
        });
      })
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      access_token: null,
      refresh_token: null
    });
  }
}
