import {createReducer, on} from '@ngrx/store';
import * as PropositionAction from '../actions/proposotion.actions'
import {PropositionModel} from "../models/proposition.model";

export const PropositionFeatureKey = 'proposition';

export interface State {
  proposition: PropositionModel | null,
  InProgress: PropositionModel[]
  loading: boolean,
  error: any

}

export const initialState: State = {
  proposition: null,
  InProgress: [],
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(PropositionAction.loadProposition, (state) => ({...state, loading: true, error: null})),
  on(PropositionAction.loadPropositionSuccess, (state, {data}) => ({
    ...state,
    proposition: data,
    loading: false,
    error: null
  })),
  on(PropositionAction.loadPropositionFailure, (state, {error}) => ({...state, loading: false, error})),
  on(PropositionAction.updatePropositionTagSuccess, (state, {data}) => ({
    ...state,
    proposition: (state.proposition ? {...state.proposition, tags: [...data]} : null),
    loading: false,
    error: null
  })),
  on(PropositionAction.updatePropositionTagCommentDateSuccess, (state, {data}) => ({
    ...state,
    proposition: (state.proposition ? {...state.proposition, tags: [...data]} : null),
    loading: false,
    error: null
  })),
  on(PropositionAction.deletePropositionTagSuccess, (state, {data}) => ({
    ...state,
    proposition: (state.proposition ? {...state.proposition, tags: [...data]} : null),
    loading: false,
    error: null
  })),
  on(PropositionAction.loadInProgressSuccess, (state, {data}) => ({
    ...state,
    InProgress: data,
    loading: false,
    error: null
  })),
);


