import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {SubscriberModel} from "../models/subscriber.model";


const API_URL = environment.API_URL;
const PATH = '/search/'

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) {
  }

  search(search: string) {
    return this.http.get<SubscriberModel[]>(API_URL + PATH + search)
  }
}
