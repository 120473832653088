import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./shared/guard/auth.guard";

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/dashboard/activities' },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/feature/dashboard-shell/dashboard-shell.module').then(m => m.DashboardShellModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/feature/login.module').then(m => m.LoginModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/feature/logout.module').then(m => m.LogoutModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./user/feature/user-shell/user-shell.module').then(m => m.UserShellModule)
  },
  {
    path: 'subscribers',
    loadChildren: () => import('./subscriber/feature/subscriber-shell/subscriber-shell.module').then(m => m.subscriberShellModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/feature/profile-shell/profile-shell.module').then(m => m.profileShellModule)
  },
  {
    path: 'jobOffer',
    loadChildren: () => import('./jobOffer/feature/jobOffer-shell/jobOffer-shell.module').then(m => m.jobOfferShellModule)
  },
  {
    path: 'proposition',
    loadChildren: () => import('./propositions/feature/proposition-shell/proposition-shell.module').then(m => m.PropositionShellModule)
  },
  {
    path: 'stats',
    loadChildren: () => import('./stats/feature/stats-shell/stats-shell.module').then(m => m.StatsShellModule)
  },
  {
    path: '404',
    loadChildren: () => import('./notFound/feature/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: '500',
    loadChildren: () => import('./error/feature/error.module').then(m => m.ErrorModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    initialNavigation: 'enabledBlocking',
    // anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
