import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";


const API_URL = environment.API_URL;
const PATH = '/commentary/'

@Injectable({
  providedIn: 'root'
})
export class CommentaryService {

  constructor(private http: HttpClient) {
  }

  loadCommentaries(profile: string, profile_id: string) {
    return this.http.get<any>(API_URL + PATH + profile + '/' + profile_id)
  }

  storeCommentary(profile: string, profile_id: string, comment: string, proposition_id: string | null) {
    return this.http.post<any>(API_URL + PATH + profile + '/' + profile_id, {comment, proposition_id: proposition_id})
  }

  updateCommentary(commentary_id: string, comment: string) {
    return this.http.patch<any>(API_URL + PATH + commentary_id, {comment})
  }

  deleteCommentary(commentary_id: string) {
    return this.http.delete<any>(API_URL + PATH + commentary_id)
  }
}
