export class Sort {

  private sortOrder = 1;
  private collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  })

  constructor() { }

  public resolve(obj: any, path: any) {
    path = path.split('.');
    let current = obj;
    while(path.length) {
      if(typeof current !== 'object') return undefined;
      current = current[path.shift()];
    }
    return current;
  }

  public startSort(property: any, order: any, type = "") {
    if (order === "desc") {
      this.sortOrder = -1;
    }
    return (a: any, b: any) => {
      const resolvedA = this.resolve(a, property)
      const resolvedB = this.resolve(b, property)
      if (type === "date") {
        return this.sortData(new Date(resolvedA), new Date(resolvedB));
      } else {
        return this.collator.compare(resolvedA, resolvedB) * this.sortOrder;
      }
    }
  }

  private sortData(a: any, b: any) {
    if (a < b) {
      return -1 * this.sortOrder;
    } else if (a > b) {
      return 1 * this.sortOrder;
    } else {
      return 0 * this.sortOrder;
    }
  }
}
