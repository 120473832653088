import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";

const API_URL = environment.API_URL;
const PATH = '/sourcingCampaign/'

@Injectable({
  providedIn: 'root'
})
export class SourcingService {

  constructor(private http: HttpClient) {
  }

  load() {
    return this.http.get<any>(API_URL + PATH)
  }

  current() {
    return this.http.get<any>(API_URL + PATH + 'current')
  }

  get(id: string) {
    return this.http.get<any>(API_URL + PATH + id)
  }

  store(duplicate: boolean) {
    return this.http.post<any>(API_URL + PATH, {duplicate})
  }

  updateJobOffer(id: string, jobOfferId: string, fromUserId: string, targetUserId: string) {
    return this.http.patch<any>(API_URL + PATH + id + '/jobOffer/' + jobOfferId, {fromUserId, targetUserId})
  }
}
